import { useContext, useState } from 'react';
import MultiSelectWithSearch, { MultiSelectOptions } from '../../MultiSelectWithSearch';
import { ROAutocomplete } from 'shared-components/components/FormFields';
import { styled } from '@mui/system';
import { DashboardContext } from '../DashboardContext';
import { SelectOptionType } from 'shared-components/components/FormFields';
import LocationOn from '@mui/icons-material/LocationOn';
import PersonSearch from '@mui/icons-material/PersonSearch';
import FilterList from '@mui/icons-material/FilterList';
import Event from '@mui/icons-material/Event';
import { Typography, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const StyledDropDown = styled(ROAutocomplete)`
  width: 100%;
  min-width: 100%;
  border-radius: 4px;
  border: 1px ${(props) => props.theme.palette.text.secondary} solid;
  font-size: 14px;
  padding: 0;
  margin: 0;
  height: 40px;
`;

const monthNames = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const subDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const getEarliestDate = (data: { submittedAt: string }[]): string => {
  return data.reduce((earliest, current) => {
    return new Date(current.submittedAt) < new Date(earliest.submittedAt) ? current : earliest;
  }).submittedAt;
};

const dateRangeMap: Record<string, number> = {
  'Past 30 days': 30,
  'Past 90 days': 90,
  'Past 6 months': 180,
  'Past 1 year': 365,
  'All time': 0,
};

const DashboardHeader = (props: { title: string }): JSX.Element => {
  const { title } = props;

  const {
    filteredData,
    dateRange,
    endDate,
    tumourStreams,
    physicians,
    sites,
    tumourStreamOptions,
    physicianOptions,
    siteOptions,
    setEndDate,
    setDateRange,
    setTumourStreams,
    setPhysicians,
    setSites,
  } = useContext(DashboardContext);

  const selectedDate =
    dateRange === 'All time' ? new Date(getEarliestDate(filteredData)) : subDays(endDate, dateRangeMap[dateRange]);

  const theme = useTheme();

  const [showAllSites, setShowAllSites] = useState<boolean>(false);
  const [showAllPhysicians, setShowAllPhysicians] = useState<boolean>(false);
  const [showAllTumourStreams, setShowAllTumourStreams] = useState<boolean>(false);

  const isSmallScreen = useMediaQuery('(max-width:1600px)');

  return (
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        background: theme.palette.background.default,
        zIndex: 2,
        width: title !== 'Insights Dashboard' ? '100%' : isSmallScreen ? '1200px' : '1600px',
      }}>
      <Stack alignItems="center" width="100%" margin="12px 0 24px">
        <Stack direction="row" alignItems="end" width="100%">
          <Typography variant="h5" sx={{ marginRight: '10px' }}>
            {title}
          </Typography>
          <Typography color={theme.palette.text.secondary} sx={{ paddingBottom: '1px' }}>
            {monthNames[selectedDate.getMonth()]} {selectedDate.getDate()}, {selectedDate.getFullYear()} -{' '}
            {monthNames[endDate.getMonth()]} {endDate.getDate()}, {endDate.getFullYear()}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        maxWidth="100%"
        width="100%"
        height="50px"
        marginBottom="10px">
        <Typography
          color={theme.palette.text.secondary}
          sx={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
          Filters:
        </Typography>
        <Stack width="100%" marginRight={2} paddingLeft={1} position="relative" className="dashboard-filter date">
          <StyledDropDown
            id="filter-by-date"
            icon={<Event />}
            placeholder={'Date'}
            value={{ label: dateRange, value: dateRange }}
            label="Date"
            options={Object.entries(dateRangeMap).map(
              ([key]): SelectOptionType => ({
                label: key,
                value: key,
              }),
            )}
            onChange={(option: SelectOptionType | string) => {
              const value = typeof option === 'string' ? option : option['value'];
              setDateRange(value);
              setEndDate(new Date());
            }}
          />
        </Stack>
        <Stack width="100%" margin="0 8px" position="relative" className="dashboard-filter">
          <MultiSelectWithSearch
            limitTags={2}
            size="small"
            adornmentIcon={sites.length === siteOptions.length && !showAllSites ? undefined : <LocationOn />}
            options={siteOptions}
            id={'multi-select-search-filter-by-site'}
            value={sites.length === siteOptions.length && !showAllSites ? [] : sites}
            label={sites.length === siteOptions.length && !showAllSites ? '' : 'Sites'}
            placeholder={sites.length === siteOptions.length && !showAllSites ? 'Sites' : ''}
            onChange={(values: MultiSelectOptions[]) => {
              setShowAllSites(values.length === siteOptions.length);
              setSites(values);
            }}
            disableCloseOnSelect
          />
        </Stack>
        <Stack width="100%" margin="0 8px" position="relative" className="dashboard-filter">
          <MultiSelectWithSearch
            limitTags={2}
            size="small"
            adornmentIcon={
              physicians.length === physicianOptions.length && !showAllPhysicians ? undefined : <PersonSearch />
            }
            options={physicianOptions}
            id={'multi-select-search-filter-by-physician'}
            value={physicians.length === physicianOptions.length && !showAllPhysicians ? [] : physicians}
            label={physicians.length === physicianOptions.length && !showAllPhysicians ? '' : 'Physicians'}
            placeholder={physicians.length === physicianOptions.length && !showAllPhysicians ? 'Physicians' : ''}
            onChange={(values: MultiSelectOptions[]) => {
              setShowAllPhysicians(values.length === physicianOptions.length);
              setPhysicians(values);
            }}
            disableCloseOnSelect
          />
        </Stack>
        <Stack width="100%" marginLeft={1} position="relative" className="dashboard-filter">
          <MultiSelectWithSearch
            limitTags={2}
            size="small"
            adornmentIcon={
              tumourStreams.length === tumourStreamOptions.length && !showAllTumourStreams ? undefined : <FilterList />
            }
            options={tumourStreamOptions}
            id={'multi-select-search-filter-by-tumour-stream'}
            value={tumourStreams.length === tumourStreamOptions.length && !showAllTumourStreams ? [] : tumourStreams}
            label={tumourStreams.length === tumourStreamOptions.length && !showAllTumourStreams ? '' : 'Tumor streams'}
            placeholder={
              tumourStreams.length === tumourStreamOptions.length && !showAllTumourStreams ? 'Tumor streams' : ''
            }
            onChange={(values: MultiSelectOptions[]) => {
              setShowAllTumourStreams(values.length === tumourStreamOptions.length);
              setTumourStreams(values);
            }}
            disableCloseOnSelect
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DashboardHeader;
