import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartStackedColumn from '../Charts/ChartStackedColumn';
import SubChartBar from '../Charts/SubChartBar';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import { aggregateStackedBarData, aggregateData, aggregateDataMax, aggregateDataSum } from '../../Utils/dataFunctions';

const Diagnosis = ({
  theme,
  data,
  subChartData,
  dateFilterOverride,
  selectedIndex,
  seeMoreUpdated,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  endDate,
  toggleSeeMoreBottomLeft,
  toggleSeeMoreBottomRight,
  onPointClick,
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartStackedColumn({
      onPointClick: onPointClick,
      selectedPoint: dateFilterOverride,
      selectedIndex: selectedIndex,
      seeMoreUpdated: seeMoreUpdated,
      aggregation: dateAggregationMap[dateRange!],
      dateRange: dateRange,
      endDate: endDate,
      leftSeriesTitle: '',
      rightSeriesTitle: '',
      leftSeriesFields: aggregateStackedBarData(
        data,
        'tumourStream',
        dateAggregationMap[dateRange!],
        '',
        [
          'breast',
          'urogenital',
          'metastasis',
          'lung',
          'h&n',
          'brain',
          'gi',
          'gynae',
          'skin',
          'sarcoma',
          'haematological',
          'misc',
          'Unspecified',
        ],
        true,
      )[0],
      rightSeriesFields: ['submittedAt', 'Techniques'],
    }).components?.[0] ?? {},
    SubChartBar({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      theme: theme,
      yAxisMax: aggregateDataMax(subChartData, 'diagnosisCode') * 2,
      toggleSeeMore: toggleSeeMoreBottomLeft,
      dataLength: aggregateData(subChartData, 'diagnosisCode').length - 1,
      dataTotal: aggregateDataSum(subChartData, 'diagnosisCode'),
    }).components?.[0] ?? {},
    SubChartBar({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomRight,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      theme: theme,
      yAxisMax: aggregateDataMax(subChartData, 'createdBy') * 2,
      toggleSeeMore: toggleSeeMoreBottomRight,
      dataLength: aggregateData(subChartData, 'createdBy').length - 1,
      dataTotal: aggregateDataSum(subChartData, 'createdBy'),
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: aggregateStackedBarData(data, 'tumourStream', dateAggregationMap[dateRange!], '', [], true),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'diagnosisCode', seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'createdBy', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default Diagnosis;
