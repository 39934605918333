import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
  getWeekendBreaks,
  dateRangeMap,
  subDays,
} from './DefaultConfig';
import { InsightsDateAggregation } from 'oncology_portal/src/enums';
import Highcharts from 'highcharts';

const ChartColumn = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
  leftSeriesTitle,
  rightSeriesTitle,
  leftSeriesFields,
  rightSeriesFields,
  aggregation,
  dateRange,
  endDate,
}: TopChartParams): Dashboards.Board.Options => {
  const columnAssignment = [
    {
      seriesId: 'series-0',
      data: leftSeriesFields,
    },
  ];

  if (rightSeriesTitle) {
    columnAssignment.push({
      seriesId: 'series-1',
      data: rightSeriesFields,
    });
  }

  const series: Highcharts.SeriesOptionsType[] = [
    {
      id: 'series-0',
      type: 'column',
      name: leftSeriesTitle,
      yAxis: 0,
      ...(aggregation === InsightsDateAggregation.DAYS && {
        pointPlacement: 'on',
        pointRange: 24 * 60 * 60 * 1000,
      }),
    },
  ];

  if (rightSeriesTitle) {
    series.push({
      id: 'series-1',
      type: 'spline',
      name: rightSeriesTitle,
      marker: {
        enabled: false,
      },
    });
  }

  return {
    components: [
      {
        cell: 'top-chart',
        connector: {
          id: 'top-chart-data',
          columnAssignment: columnAssignment,
        },
        sync: syncConfig,
        type: 'Highcharts',
        chartOptions: {
          exporting: exportingConfig,
          chart: {
            type: 'column',
            animation: false,
            spacing: chartSpacingConfig,
            events: {
              load() {
                setHeight(this);
              },
              redraw() {
                selectPoint(this, selectedPoint, selectedIndex);
              },
            },
          },
          title: { text: '' },
          xAxis: {
            type: aggregation === InsightsDateAggregation.DAYS ? 'datetime' : 'category',
            ordinal: false,
            breaks: getWeekendBreaks(subDays(endDate!, dateRangeMap[dateRange!] - 1), endDate!),
            dateTimeLabelFormats: {
              day: '%m/%d',
            },
            min:
              aggregation === InsightsDateAggregation.DAYS
                ? subDays(endDate!, dateRangeMap[dateRange!] - 1).getTime()
                : null,
            max: aggregation === InsightsDateAggregation.DAYS ? endDate!.getTime() + 24 * 60 * 60 * 1000 : null,
          },
          yAxis: [
            { title: { text: '' } },
            {
              title: { text: '' },
              opposite: true,
            },
          ],
          credits: creditsConfig,
          tooltip: {
            formatter: function () {
              let tooltip = '';

              if (aggregation === InsightsDateAggregation.DAYS) {
                tooltip += `${Highcharts.dateFormat('%m/%d/%Y', Number(this.x))}<br/>`;
              } else {
                tooltip += `${this.point.name}<br/><br/>`;
              }

              // Add data for each point
              this.points!.forEach(function (point) {
                if (!point.series.name.includes('VS')) {
                  tooltip += `${point.series.name}: ${point.y?.toFixed(0)}<br/>`;
                }
              });

              return tooltip;
            },
            shared: true,
          },
          series: series,
          plotOptions: {
            column: {
              borderRadius: 8,
            },
            series: {
              cursor: 'pointer',
              animation: selectedPoint === '' && !seeMoreUpdated,
              states: {
                select: {
                  enabled: false,
                },
              },
              point: {
                events: {
                  click: function () {
                    handlePointClick(this, onPointClick);
                  },
                },
              },
            },
          },
        },
      },
    ],
  };
};

export default ChartColumn;
