import fetch from 'unfetch';
import React from 'react';
import { ApolloClient, ApolloLink, Operation, ServerError } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { RetryLink } from '@apollo/client/link/retry';
import Cookies from 'js-cookie';
import { FormatSpecialCharactersMiddleware } from 'shared-components/utils';
import { gql } from '@apollo/client';
import { determineError } from 'shared-components/models/ApolloHandlerModel';
import { Logger } from 'shared-components/utils';
import { ApolloProvider } from '@apollo/client';
import { useMemo } from 'react';
import ErrorModal from 'op-components/ErrorModal/ErrorModal';
import { ERROR_TYPE, useErrorModalContext } from 'op-contexts/ErrorModalContext/ErrorModalContext';
import { NetworkError } from '@apollo/client/errors';
import { GraphQLFormattedError } from 'graphql';

const logger = new Logger('index');

const logToSentry = (
  operation: Operation,
  graphQLErrors: readonly GraphQLFormattedError[] | undefined,
  networkError: NetworkError | undefined,
) => {
  // Creates an object from the operation and error and logs it to Sentry
  const requestInfo = {
    operationName: operation?.operationName ?? 'Unknown',
    body: operation?.query?.loc?.source?.body ?? 'Unknown',
    variables: operation.variables || 'Unknown',
    graphQLErrors: JSON.stringify(graphQLErrors),
    networkErrors: JSON.stringify(networkError),
  };

  const alertTitle = `Operation ${operation?.operationName} Failed`;
  logger.error(alertTitle, requestInfo);
};

const createErrorLink = (setError: (errorType: ERROR_TYPE) => void) => {
  /*
    Global error handler for all Apollo Graphql requests.
  */
  return onError(({ graphQLErrors, networkError, operation }): void => {
    const context = operation.getContext();
    const locallyHandledErrors: string[] = context.locallyHandledErrors || [];

    if (networkError) {
      const networkStatusCode: number = (networkError as ServerError)?.statusCode ?? -1;
      // "Service Unavailable"
      if (networkStatusCode === 503) {
        window.location.reload();
        return;
      }

      // "Forbidden"
      // TODO Can't actually see anything that would trigger this. Could a 403 come from something we don't want to redirect for?
      if (networkStatusCode === 403) {
        window.location.replace('/sso/login');
        return;
      }

      // Catch any random network errors, this includes full network failures
      // TODO type is not a valid property of networkError, so this might not actually ever trigger
      // @ts-ignore
      if (networkError.type == 'error' && networkStatusCode === -1) {
        // @ts-ignore
        setError('Generic');
      }

      // For anything else, don't trigger the error modal but still log to Sentry.
      // This is the behavior prior to refactor, but we should add specific handling of any other errors that come through
      logToSentry(operation, graphQLErrors, networkError);
      return;
    }

    // Certain session expiry is returned by the backend as a graphQLError with a stringified JSON object in the message like this
    // LOGGED_IN_ERROR = json.dumps(dict(message="You must be logged in", statusCode=403))
    // TODO: handle these with just a message or returning a network error
    const formattedError = determineError(graphQLErrors?.[0]);
    if (formattedError?.statusCode === 403) {
      window.location.replace('/sso/login');
      return;
    }

    // Handle all other graphQLErrors
    if (graphQLErrors && graphQLErrors.length) {
      graphQLErrors.forEach((error) => {
        if (locallyHandledErrors.includes(error.message)) {
          // This has been set at the useQuery or useMutation so we don't need to handle it here
          return;
        }

        // Trigger the error popup
        setError('Generic');

        // Send the error to Sentry
        logToSentry(operation, graphQLErrors, networkError);
      });
      // We had errors and we've handled them, so return
    } else {
      // For some reason this function fired but there were no errors to handle
      // This should never happpen, but we want a fallback just in case it does for now
      logToSentry(operation, graphQLErrors, networkError);
    }
  });
};

const httpLink = createHttpLink({ uri: '/server/graphql', fetch });

const csrfMiddleware = new ApolloLink((operation: any, forward) => {
  operation.setContext(({ headers = {} }: { headers: any }): any => ({
    headers: {
      ...headers,
      'X-CSRFTOKEN': Cookies.get('csrftoken'),
    },
  }));

  return forward(operation);
});

const EXTEND_LOCK = 'ExtendLock';
const OPERATIONS_TO_IGNORE = [EXTEND_LOCK];

let sessionTimeout: NodeJS.Timeout;
// Handle redirecting the user when their session has expired
const sessionTimeoutLink = new ApolloLink((operation: any, forward: any) => {
  return forward(operation).map((response: any) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    const expiry = headers.get('X-Expiry-Seconds');

    // currently timeout set for 15 mins for patients, 24h for staff
    const userRedirectMapping = {
      staff: '/',
      patientInClinic: '/patient',
      patientOpHomeRego: '/timeout',
      patientPortal: '/timeout',
      patient: '/timeout',
    };
    const userType = headers.get('X-User-Type');
    let redirectTo = userRedirectMapping[userType as keyof typeof userRedirectMapping];

    // Convert a GraphQL object to a normal Javascript object to have methods like `hasOwnProperty`
    const data = JSON.parse(JSON.stringify(context.cache.data.data));

    // Iterate over the GraphQL cache to try to tease out the logged in User object
    const getUserProperty = Object.keys(data)
      .map((property: any) =>
        data.hasOwnProperty(property) && property.toString().startsWith('UserType') ? data[property] : null,
      )
      .filter(Boolean);

    const props = ['isRo', 'isPso', 'isSuperuser'];

    // Change redirection path if a User has one of the above properties and one of those properties is true
    redirectTo =
      getUserProperty.length &&
      props.some((prop) => getUserProperty[0].hasOwnProperty(prop) && getUserProperty[0][prop])
        ? '/'
        : redirectTo;

    if (!expiry) return response;

    if (expiry && !OPERATIONS_TO_IGNORE.includes(operation?.operationName)) {
      if (sessionTimeout) clearTimeout(sessionTimeout);
      sessionTimeout = setTimeout(() => {
        if (document.location.pathname !== redirectTo) {
          // Redirect if not on login
          document.location.href = redirectTo;
          document.cookie = 'SESSION_COOKIE_AGE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
      }, 1000 * expiry); // Expiry is in seconds, timeout takes ms
    }
    return response;
  });
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => {
      const statusCode = error.statusCode ?? -1;
      if (statusCode === 0) {
        return true;
      }
      return false;
    },
  },
});

const createApolloClient = (setError: (errorType: ERROR_TYPE) => void) => {
  const errorLink = createErrorLink(setError);
  const link = ApolloLink.from([
    FormatSpecialCharactersMiddleware,
    errorLink,
    sessionTimeoutLink,
    csrfMiddleware,
    retryLink,
    httpLink,
  ]);

  const cache = new InMemoryCache();

  // Apollo client
  const apolloClient = new ApolloClient({
    link,
    cache: cache,
    connectToDevTools: true,
  });

  cache.writeQuery({
    query: gql`
      query {
        pendingSaveCount
        saveErrorCount
        registrationPagesViewed
        error {
          message
          statusCode
        }
      }
    `,
    data: {
      pendingSaveCount: 0,
      saveErrorCount: 0,
      registrationPagesViewed: [],
      error: {
        __typename: 'Error',
        message: '',
        statusCode: -1,
      },
    },
  });
  return apolloClient;
};

export const ApolloProviderWithError = ({ children }: { children: React.ReactNode }) => {
  const { error, setError } = useErrorModalContext();
  const client = useMemo(() => createApolloClient(setError), [setError]);
  return (
    <>
      <ApolloProvider client={client}>
        {children}
        <ErrorModal key={error ? 'error-modal' : 'no-error'} />
      </ApolloProvider>
      ;
    </>
  );
};
