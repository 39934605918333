import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
  getWeekendBreaks,
  dateRangeMap,
  subDays,
} from './DefaultConfig';
import { InsightsDateAggregation } from 'oncology_portal/src/enums';
import Highcharts from 'highcharts';

const columnSeriesMapping = (leftSeriesFields: string[]) => {
  const trimmedFields = leftSeriesFields.slice(1);
  return trimmedFields.map((field, index) => ({
    seriesId: 'series-' + index,
    data: ['submittedAt', field],
  }));
};

const seriesMapping = (leftSeriesFields: string[], aggregation: InsightsDateAggregation) => {
  const trimmedFields = leftSeriesFields.slice(1);

  return trimmedFields.map((field, index) => ({
    id: 'series-' + index,
    type: 'column',
    name: field,
    color: seriesColorMapping[field as keyof typeof seriesColorMapping] || 'rgba(158, 158, 158)',
    ...(aggregation === InsightsDateAggregation.DAYS && {
      pointPlacement: 'on',
      pointRange: 24 * 60 * 60 * 1000,
    }),
  })) as Highcharts.SeriesOptionsType[];
};

const seriesColorMapping: { [key: string]: string } = {
  Breast: 'rgba(170, 161, 253)',
  Metastasis: 'rgba(62, 167, 178)',
  Urogenital: 'rgba(255, 189, 91)',
  GI: 'rgba(251, 106, 118)',
  Brain: 'rgba(128, 0, 128)',
  Skin: 'rgba(89, 39, 218)',
  'H&N': 'rgba(0, 128, 128)',
  Lung: 'rgba(255, 99, 132)',
  Gynae: 'rgba(255, 159, 64)',
  Sarcoma: 'rgba(255, 205, 86)',
  Haematological: 'rgba(75, 192, 192)',
  Misc: 'rgba(54, 162, 235)',
  Benign: 'rgba(153, 102, 255)',
};

const ChartStackedColumn = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
  leftSeriesTitle,
  leftSeriesFields,
  aggregation,
  dateRange,
  endDate,
}: TopChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: 'top-chart',
      connector: {
        id: 'top-chart-data',
        columnAssignment: columnSeriesMapping(leftSeriesFields),
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: exportingConfig,
        chart: {
          type: 'column',
          animation: false,
          spacing: chartSpacingConfig,
          styledMode: false,
          events: {
            load() {
              setHeight(this);
            },
            redraw() {
              selectPoint(this, selectedPoint, selectedIndex);
            },
          },
        },
        title: { text: '' },
        xAxis: {
          type: aggregation === InsightsDateAggregation.DAYS ? 'datetime' : 'category',
          ordinal: false,
          breaks: getWeekendBreaks(subDays(endDate!, dateRangeMap[dateRange!] - 1), endDate!),
          dateTimeLabelFormats: {
            day: '%m/%d',
          },
          min:
            aggregation === InsightsDateAggregation.DAYS
              ? subDays(endDate!, dateRangeMap[dateRange!] - 1).getTime()
              : null,
          max: aggregation === InsightsDateAggregation.DAYS ? endDate!.getTime() + 24 * 60 * 60 * 1000 : null,
        },
        yAxis: {
          title: { text: leftSeriesTitle },
        },
        credits: creditsConfig,
        tooltip: {
          formatter: function () {
            let tooltip = '';

            if (aggregation === InsightsDateAggregation.DAYS) {
              tooltip += `${Highcharts.dateFormat('%m/%d/%Y', Number(this.x))}<br/>`;
            } else {
              tooltip += `${this.point.name}<br/><br/>`;
            }

            // Add data for each point
            this.points!.forEach(function (point) {
              if (!point.series.name.includes('VS')) {
                tooltip += `${point.series.name}: ${point.y?.toFixed(0)}<br/>`;
              }
            });

            return tooltip;
          },
          shared: true,
        },
        series: seriesMapping(leftSeriesFields, aggregation!),
        plotOptions: {
          column: {
            stacking: 'normal',
          },
          series: {
            cursor: 'pointer',
            animation: selectedPoint === '' && !seeMoreUpdated,
            states: {
              select: {
                enabled: false,
              },
            },
            point: {
              events: {
                click: function () {
                  handlePointClick(this, onPointClick);
                },
              },
            },
          },
        },
      },
    },
  ],
});

export default ChartStackedColumn;
