import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartStackedColumnPercent from '../Charts/ChartStackedColumnPercent';
import SubChartBar from '../Charts/SubChartBar';
import SubChartStackedBarPercentTotal from '../Charts/SubChartStackedBarPercentTotal';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import {
  aggregateStackedBarData,
  aggregateData,
  aggregateTechniqueByField,
  aggregateDataMax,
  aggregateDataSum,
} from '../../Utils/dataFunctions';

const TechniqueUtilisation = ({
  theme,
  data,
  subChartData,
  dateFilterOverride,
  selectedIndex,
  seeMoreUpdated,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  endDate,
  toggleSeeMoreBottomLeft,
  toggleSeeMoreBottomRight,
  onPointClick,
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartStackedColumnPercent({
      onPointClick: onPointClick,
      selectedPoint: dateFilterOverride,
      selectedIndex: selectedIndex,
      seeMoreUpdated: seeMoreUpdated,
      leftSeriesTitle: '',
      rightSeriesTitle: '',
      aggregation: dateAggregationMap[dateRange!],
      dateRange: dateRange,
      endDate: endDate,
      leftSeriesFields: aggregateStackedBarData(
        data,
        'technique',
        dateAggregationMap[dateRange!],
        'Complex Percentage',
        ['IMRT/VMAT', 'Stereotactic', 'Conformal', 'Complex', 'Brachy', 'Unspecified'],
      )[0].slice(0, -1),
      rightSeriesFields: ['submittedAt', 'Complex Percentage'],
    }).components?.[0] ?? {},
    SubChartBar({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomLeft,
      title: 'Case Mix',
      connector: 'sub-chart-left-data',
      cell: 'sub-chart-left',
      theme: theme,
      yAxisMax: aggregateDataMax(subChartData, 'tumourStream') * 2,
      toggleSeeMore: toggleSeeMoreBottomLeft,
      dataLength: aggregateData(subChartData, 'tumourStream').length - 1,
      dataTotal: aggregateDataSum(subChartData, 'tumourStream'),
    }).components?.[0] ?? {},
    SubChartStackedBarPercentTotal({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomRight,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-right',
      theme: theme,
      toggleSeeMore: toggleSeeMoreBottomRight,
      dataLength: aggregateTechniqueByField(subChartData, 'createdBy').length - 1,
      seriesFields: aggregateTechniqueByField(subChartData, 'createdBy')[0].map((item) => item.toString()),
      dataTotal: aggregateDataSum(subChartData, 'createdBy'),
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: aggregateStackedBarData(data, 'technique', dateAggregationMap[dateRange!], 'Complex Percentage'),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'tumourStream', seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        firstRowAsNames: true,
        data: aggregateTechniqueByField(subChartData, 'createdBy', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT, [
          'IMRT/VMAT',
          'Stereotactic',
          'Conformal',
          'Complex',
          'Brachy',
          'Unspecified',
        ]),
      },
    },
  ],
});

export default TechniqueUtilisation;
