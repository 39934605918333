import { syncConfig, BottomChartParams, seeMoreButtonStyle } from './DefaultConfig';

const SubChartTable = ({
  filterOverride,
  seeMore,
  title,
  connector,
  cell,
  toggleSeeMore,
  dataLength,
}: BottomChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: cell,
      connector: { id: connector },
      sync: syncConfig,
      type: 'DataGrid',
      dataGridClassName: 'chart-data-grid',
      title: {
        text: title,
      },
      dataGridOptions: {
        resizableColumns: false,
        editable: false,
      },
      events: {
        load() {
          const contentElement =
            typeof this.contentElement === 'function' ? this.contentElement() : this.contentElement;

          const seeMoreButton = document.createElement('div');
          Object.assign(seeMoreButton.style, seeMoreButtonStyle);

          seeMoreButton.style.position = 'absolute';
          seeMoreButton.style.bottom = '10px';

          seeMoreButton.addEventListener('mouseenter', () => {
            seeMoreButton.style.backgroundColor = 'rgba(8, 30, 67, 0.05)';
          });

          seeMoreButton.addEventListener('mouseleave', () => {
            seeMoreButton.style.backgroundColor = 'transparent';
          });

          seeMoreButton.textContent = seeMore ? 'SEE LESS' : 'SEE MORE';
          seeMoreButton.onclick = () => {
            toggleChartHeight(contentElement, seeMoreButton);
            toggleSeeMore();
          };
          if (seeMore) {
            contentElement.classList.add('expanded');
            contentElement.parentElement?.classList.add('expanded');
          }

          if (dataLength! - 1 > 3) {
            contentElement.appendChild(seeMoreButton);
          }

          const toggleChartHeight = (container: any, button: any) => {
            container.classList.toggle('expanded');
            container.parentElement?.classList.toggle('expanded');

            if (container.classList.contains('expanded')) {
              button.textContent = 'SEE LESS';
            } else {
              button.textContent = 'SEE MORE';
            }
          };
        },
      },
    },
  ],
});

export default SubChartTable;
