import { findParentById } from '../../Utils/utils';
import { InsightsDateAggregation } from 'oncology_portal/src/enums';

const CHART_SIZE_OFFSET = 580;
const chartSpacingConfig = [30, 20, 10, 20];

const syncConfig = {
  visibility: true,
  highlight: true,
  extremes: true,
};

const creditsConfig = {
  enabled: false,
};

const exportingConfig = {
  buttons: {
    contextButton: {
      menuItems: ['downloadCSV', 'downloadXLS'],
      y: -20,
      x: 5,
      symbol: 'download',
      symbolSize: 24,
    },
  },
};

const subExportingConfig = {
  buttons: {
    contextButton: {
      menuItems: ['downloadCSV', 'downloadXLS'],
      x: 110,
      y: -7,
      symbol: 'download',
      symbolSize: 24,
    },
  },
};

const handlePointClick = (point: Highcharts.Point, onPointClick: (seriesName: string, seriesIndex: number) => void) => {
  onPointClick(point.name, point.index);
};

// hacking the chart height to fit the container, because highcharts doesnt play well with flexbox
const setHeight = (chart: Highcharts.Chart) => {
  const currentElement = findParentById(chart.container, 'ROContainer');
  const parentSize = currentElement?.clientHeight;

  if (parentSize) {
    chart.setSize(undefined, Number((parentSize - CHART_SIZE_OFFSET).toFixed(0)));
  }
};

const selectPoint = (chart: Highcharts.Chart, selectedPoint: string, selectedIndex: number) => {
  chart.series.forEach(function (series) {
    if (series.visible) {
      const point = series.data[selectedIndex];

      if (point && selectedPoint) {
        if (point.graphic?.element) {
          point.graphic.element.style.fillOpacity = '1';
        }
      }
    }
  });
};

function getWeekendBreaks(startDate: Date, endDate: Date): { from: number; to: number }[] {
  const breaks: { from: number; to: number }[] = [];
  const startDateUTC = startDate.getTime();
  const endDateUTC = endDate.getTime();
  let currentDate = startDateUTC;

  while (currentDate < endDateUTC) {
    const dayOfWeek = new Date(currentDate).getUTCDay();

    if (dayOfWeek === 6) {
      // 6 is Saturday
      const weekendEnd = currentDate + 2 * 24 * 36e5; // Adding 2 days for weekend
      breaks.push({
        from: currentDate,
        to: weekendEnd,
      });
    }

    // Move to the next day
    currentDate += 24 * 36e5;
  }
  return breaks;
}

interface TopChartParams {
  onPointClick: (seriesName: string) => void;
  selectedPoint: string;
  selectedIndex: number;
  seeMoreUpdated: boolean;
  leftSeriesTitle: string;
  rightSeriesTitle: string;
  leftSeriesFields: string[];
  rightSeriesFields: string[];
  extraSeriesFields?: string[];
  dateRange?: string;
  endDate?: Date;
  aggregation?: InsightsDateAggregation;
}

interface BottomChartParams {
  title: string;
  connector: string;
  cell: string;
  filterOverride: string;
  seeMore: boolean;
  precision?: number;
  dataLabelTitle?: string;
  dataLabelTitleWidth?: number;
  yAxisMax?: number;
  theme: any;
  toggleSeeMore: () => void;
  dataLength?: number;
  seriesFields?: string[];
  dataTotal?: number;
}

const seeMoreButtonStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  padding: '8px',
  bottom: '45px',
  left: '20px',
  cursor: 'pointer',
  borderRadius: '4px',
  border: '1px solid rgba(8, 30, 67, 0.50)',
  width: '100px',
  fontSize: '14px',
  fontWeight: '500',
  zIndex: '1',
};

const dateRangeMap: Record<string, number> = {
  'Past 30 days': 30,
  'Past 90 days': 90,
  'Past 6 months': 180,
  'Past 1 year': 365,
  'All time': 0,
};

const loadSeeMoreButton = (chart: Highcharts.Chart, toggleSeeMore: () => void, seeMore: boolean) => {
  const container = chart.container;

  const seeMoreButton = document.createElement('div');

  Object.assign(seeMoreButton.style, seeMoreButtonStyle);

  seeMoreButton.addEventListener('mouseenter', () => {
    seeMoreButton.style.backgroundColor = 'rgba(8, 30, 67, 0.05)';
  });

  seeMoreButton.addEventListener('mouseleave', () => {
    seeMoreButton.style.backgroundColor = 'transparent';
  });

  seeMoreButton.textContent = seeMore ? 'SEE LESS' : 'SEE MORE';
  seeMoreButton.onclick = () => {
    toggleChartHeight(container, seeMoreButton);
    toggleSeeMore();
  };
  if (seeMore) {
    container.classList.add('expanded');
    container.parentElement?.parentElement?.classList.add('expanded');
    container.parentElement?.parentElement?.parentElement?.classList.add('expanded');
  }
  container.appendChild(seeMoreButton);

  const toggleChartHeight = (container: any, button: any) => {
    container.classList.toggle('expanded');
    container.parentElement?.parentElement?.classList.toggle('expanded');
    container.parentElement.parentElement.parentElement.classList.toggle('expanded');

    if (container.classList.contains('expanded')) {
      button.textContent = 'SEE LESS';
    } else {
      button.textContent = 'SEE MORE';
    }
  };
};

const subDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

export {
  setHeight,
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  subExportingConfig,
  loadSeeMoreButton,
  handlePointClick,
  selectPoint,
  TopChartParams,
  BottomChartParams,
  CHART_SIZE_OFFSET,
  seeMoreButtonStyle,
  getWeekendBreaks,
  dateRangeMap,
  subDays,
};
