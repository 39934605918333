import { useEffect, useContext, useState } from 'react';
import Dashboards from '@highcharts/dashboards';

import DashboardHeader from './DashboardHeader';
import { DashboardContext } from '../DashboardContext';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Dashboard = (props: {
  title: string;
  metric: string;
  metricFunction: (data: any[]) => number;
  config: any;
  metricSuffix?: string;
  metricRound?: number;
  dataType: string;
}): JSX.Element => {
  const {
    filteredData,
    previousPeriodData,
    subChartFilteredData,
    dateFilterOverride,
    selectedPointIndex,
    dateRange,
    endDate,
    handleDateClick,
    onChartClick,
  } = useContext(DashboardContext);
  const { title, config, metric, metricSuffix = '', metricRound = 0, dataType, metricFunction } = props;

  const [previousPeriodMetric, setPreviousPeriodMetric] = useState<number>(0);
  const [metricChangeIndicator, setMetricChangeIndicator] = useState<string>('');
  const [seeMoreBottomLeft, setSeeMoreBottomLeft] = useState<boolean>(false);
  const [seeMoreBottomRight, setSeeMoreBottomRight] = useState<boolean>(false);
  const [seeMoreUpdated, setSeeMoreUpdated] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  const theme = useTheme();

  useEffect(() => {
    const data = filteredData.filter((d: any) => d.__typename === dataType);
    const subChartData = subChartFilteredData.filter((d: any) => d.__typename === dataType);
    const previousPeriodDataFiltered = previousPeriodData.filter((d: any) => d.__typename === dataType);

    setData(data);

    Dashboards.board(
      'dashboard-container',
      config({
        theme: theme,
        data: data,
        subChartData: subChartData,
        dateFilterOverride: dateFilterOverride,
        selectedIndex: selectedPointIndex,
        seeMoreUpdated: seeMoreUpdated,
        seeMoreBottomLeft: seeMoreBottomLeft,
        seeMoreBottomRight: seeMoreBottomRight,
        dateRange: dateRange,
        endDate: endDate,
        toggleSeeMoreBottomLeft: toggleSeeMoreBottomLeft,
        toggleSeeMoreBottomRight: toggleSeeMoreBottomRight,
        onPointClick: handleDateClick,
      }),
    );

    const current = metricFunction(data);
    const previous = metricFunction(previousPeriodDataFiltered);
    const percentChange = (Math.abs(current - previous) / previous) * 100;
    setPreviousPeriodMetric(percentChange);
    setMetricChangeIndicator(current > previous ? 'more' : 'less');

    setSeeMoreUpdated(false);
  }, [subChartFilteredData, seeMoreBottomLeft, seeMoreBottomRight]);

  const toggleSeeMoreBottomLeft = () => {
    setSeeMoreUpdated(true);
    setSeeMoreBottomLeft(!seeMoreBottomLeft);
    onChartClick();
  };

  const toggleSeeMoreBottomRight = () => {
    setSeeMoreUpdated(true);
    setSeeMoreBottomRight(!seeMoreBottomRight);
    onChartClick();
  };

  return (
    <Stack height="100%">
      <DashboardHeader title={title} />
      <Stack
        justifyContent="center"
        alignItems="center"
        fontSize="24px"
        borderRadius="8px"
        marginTop="20px"
        p="20px 0"
        sx={{ backgroundColor: 'background.secondary' }}>
        <Typography variant="h5">
          {metricFunction(data).toFixed(metricRound)}
          {metricSuffix} {metric}
        </Typography>
        <Typography color={theme.palette.text.secondary} height={20}>
          {dateRange !== 'All time' && !isNaN(previousPeriodMetric) && isFinite(previousPeriodMetric) && (
            <Stack alignItems="center" direction="row">
              <ArrowForward
                style={{
                  zIndex: 1,
                  marginRight: '5px',
                  transform: `rotate(${metricChangeIndicator === 'more' ? '-' : ''}90deg)`,
                  width: '16px',
                }}
              />
              {previousPeriodMetric.toFixed(metricRound)}% {metricChangeIndicator} than previous{' '}
              {dateRange.split('Past ')[1]}
            </Stack>
          )}
        </Typography>
      </Stack>
      <Stack id="dashboard-container" flex={1} className="highcharts-light" />
    </Stack>
  );
};

export default Dashboard;
