import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
  getWeekendBreaks,
  dateRangeMap,
  subDays,
} from './DefaultConfig';
import { InsightsDateAggregation } from 'oncology_portal/src/enums';
import Highcharts from 'highcharts';

const ChartColumnLine = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
  leftSeriesTitle,
  rightSeriesTitle,
  leftSeriesFields,
  rightSeriesFields,
  aggregation,
  dateRange,
  endDate,
}: TopChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: 'top-chart',
      connector: {
        id: 'top-chart-data',
        columnAssignment: [
          {
            seriesId: 'series-0',
            data: leftSeriesFields,
          },
          {
            seriesId: 'series-1',
            data: rightSeriesFields,
          },
        ],
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: exportingConfig,
        chart: {
          type: 'column',
          animation: false,
          spacing: chartSpacingConfig,
          events: {
            load() {
              setHeight(this);
            },
            redraw() {
              selectPoint(this, selectedPoint, selectedIndex);
            },
          },
        },
        title: { text: '' },
        xAxis: {
          type: aggregation === InsightsDateAggregation.DAYS ? 'datetime' : 'category',
          ordinal: false,
          breaks: getWeekendBreaks(subDays(endDate!, dateRangeMap[dateRange!] - 1), endDate!),
          dateTimeLabelFormats: {
            day: '%m/%d/%Y',
          },
          min:
            aggregation === InsightsDateAggregation.DAYS
              ? subDays(endDate!, dateRangeMap[dateRange!] - 1).getTime()
              : null,
          max: aggregation === InsightsDateAggregation.DAYS ? endDate!.getTime() + 24 * 60 * 60 * 1000 : null,
        },
        yAxis: [
          { title: { text: leftSeriesTitle } },
          {
            title: { text: rightSeriesTitle },
            opposite: true,
          },
        ],
        credits: creditsConfig,
        tooltip: {
          formatter: function () {
            let tooltip = '';

            tooltip += `${Highcharts.dateFormat('%m/%d/%Y', Number(this.x))}<br/>`;

            // Add data for each point
            this.points!.forEach(function (point) {
              if (!point.series.name.includes('VS')) {
                tooltip += `${point.series.name}: ${point.y?.toFixed(2)}<br/>`;
              }
            });

            return tooltip;
          },
          shared: true,
        },
        series: [
          {
            id: 'series-0',
            type: 'column',
            name: leftSeriesTitle,
            yAxis: 0,
          },
          {
            id: 'series-1',
            type: 'spline',
            name: rightSeriesTitle,
            marker: {
              enabled: false,
            },
            yAxis: 1,
          },
        ],
        plotOptions: {
          column: {
            borderRadius: 8,
          },
          series: {
            cursor: 'pointer',
            animation: selectedPoint === '' && !seeMoreUpdated,
            states: {
              select: {
                enabled: false,
              },
            },
            point: {
              events: {
                click: function () {
                  handlePointClick(this, onPointClick);
                },
              },
            },
          },
        },
      },
    },
  ],
});

export default ChartColumnLine;
