import {
  syncConfig,
  chartSpacingConfig,
  creditsConfig,
  exportingConfig,
  handlePointClick,
  TopChartParams,
  setHeight,
  selectPoint,
  getWeekendBreaks,
} from './DefaultConfig';

const ChartArea = ({
  onPointClick,
  selectedPoint,
  selectedIndex,
  seeMoreUpdated,
  leftSeriesTitle,
  rightSeriesTitle,
  leftSeriesFields,
  rightSeriesFields,
  extraSeriesFields,
}: TopChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: 'top-chart',
      connector: {
        id: 'top-chart-data',
        columnAssignment: [
          {
            seriesId: 'series-0',
            data: leftSeriesFields,
          },
          {
            seriesId: 'series-1',
            data: rightSeriesFields,
          },
          {
            seriesId: 'series-2',
            data: extraSeriesFields!,
          },
        ],
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: exportingConfig,
        chart: {
          type: 'area',
          animation: false,
          spacing: chartSpacingConfig,
          styledMode: false,
          events: {
            load() {
              setHeight(this);
            },
            redraw() {
              selectPoint(this, selectedPoint, selectedIndex);
            },
          },
        },
        title: { text: '' },
        xAxis: {
          type: 'category',
        },
        yAxis: [
          {
            title: { text: leftSeriesTitle },
            max: 100,
            min: 70,
            labels: {
              format: '{value}%',
            },
          },
          {
            title: { text: rightSeriesTitle },
            opposite: true,
            min: 0,
            labels: {
              format: '{value}%',
            },
          },
        ],
        credits: creditsConfig,
        tooltip: {
          pointFormat: '{series.name}: {point.y}<br/>',
          shared: true,
        },
        series: [
          {
            id: 'series-2',
            type: 'column',
            name: 'Manual careplan',
            yAxis: 0,
            legendIndex: 1,
            color: 'rgba(62, 167, 178)',
          },
          {
            id: 'series-0',
            type: 'column',
            name: leftSeriesTitle,
            yAxis: 0,
            legendIndex: 0,
            color: 'rgba(170, 161, 253)',
          },
          {
            id: 'series-1',
            type: 'spline',
            name: rightSeriesTitle,
            yAxis: 1,
            legendIndex: 2,
            color: '#F500FF',
          },
        ],
        plotOptions: {
          column: {
            stacking: 'percent',
          },
          series: {
            cursor: 'pointer',
            marker: {
              enabled: false,
              symbol: 'circle',
            },
            states: {
              select: {
                enabled: false,
              },
            },
            animation: selectedPoint === '' && !seeMoreUpdated,
            point: {
              events: {
                click: function () {
                  handlePointClick(this, onPointClick);
                },
              },
            },
          },
        },
      },
    },
  ],
});

export default ChartArea;
