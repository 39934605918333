import Dashboards from '@highcharts/dashboards';
import DataPoolOptions from '@highcharts/dashboards/es-modules/Data/DataPoolOptions';
import ChartColumn from '../Charts/ChartColumn';
import SubChartBar from '../Charts/SubChartBar';
import { EditModeConfig, GuiConfig, DashboardConfig, SUB_CHART_LIMIT, dateAggregationMap } from './DefaultConfig';
import {
  aggregateTotalSimulations,
  aggregateData,
  aggregateDataMax,
  aggregateDataSum,
} from '../../Utils/dataFunctions';

const TotalSimulations = ({
  theme,
  data,
  subChartData,
  dateFilterOverride,
  selectedIndex,
  seeMoreUpdated,
  seeMoreBottomLeft,
  seeMoreBottomRight,
  dateRange,
  endDate,
  toggleSeeMoreBottomLeft,
  toggleSeeMoreBottomRight,
  onPointClick,
}: DashboardConfig): Dashboards.Board.Options => ({
  dataPool: dataPoolConfig(data, subChartData, seeMoreBottomLeft, seeMoreBottomRight, dateRange),
  editMode: EditModeConfig,
  gui: GuiConfig,
  components: [
    ChartColumn({
      onPointClick: onPointClick,
      selectedPoint: dateFilterOverride,
      selectedIndex: selectedIndex,
      seeMoreUpdated: seeMoreUpdated,
      aggregation: dateAggregationMap[dateRange!],
      dateRange: dateRange,
      endDate: endDate,
      leftSeriesTitle: 'Simulations',
      rightSeriesTitle: '',
      leftSeriesFields: ['submittedAt', 'Simulations'],
      rightSeriesFields: ['submittedAt', 'Target'],
    }).components?.[0] ?? {},
    SubChartBar({
      filterOverride: dateFilterOverride,
      seeMore: seeMoreBottomRight,
      title: 'Physicians',
      connector: 'sub-chart-right-data',
      cell: 'sub-chart-left',
      theme: theme,
      yAxisMax: aggregateDataMax(subChartData, 'createdBy') * 2,
      toggleSeeMore: toggleSeeMoreBottomRight,
      dataLength: aggregateData(subChartData, 'createdBy').length - 1,
      dataTotal: aggregateDataSum(subChartData, 'createdBy'),
    }).components?.[0] ?? {},
  ],
});

const dataPoolConfig = (
  data: any,
  subChartData: any,
  seeMoreBottomLeft: boolean,
  seeMoreBottomRight: boolean,
  dateRange: string | undefined,
): DataPoolOptions => ({
  connectors: [
    {
      id: 'top-chart-data',
      type: 'JSON',
      options: {
        firstRowAsNames: false,
        columnNames: ['submittedAt', 'Simulations', 'Target'],
        data: aggregateTotalSimulations(data, dateAggregationMap[dateRange!]),
      },
    },
    {
      id: 'sub-chart-left-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'tumourStream', seeMoreBottomLeft ? undefined : SUB_CHART_LIMIT),
      },
    },
    {
      id: 'sub-chart-right-data',
      type: 'JSON',
      options: {
        data: aggregateData(subChartData, 'createdBy', seeMoreBottomRight ? undefined : SUB_CHART_LIMIT),
      },
    },
  ],
});

export default TotalSimulations;
