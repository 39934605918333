import { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Highcharts from 'highcharts';
import Dashboards from '@highcharts/dashboards';
import DataGrid from '@highcharts/dashboards/datagrid';
import LayoutModule from '@highcharts/dashboards/modules/layout';
import { StyledTile } from '../Dashboard';
import './InsightsDashboard.scss';
import Dashboard from './Components/Dashboard';
import { DashboardContextProvider } from './DashboardContext';
import MockData from './Utils/MockData';
import { GET_CAREPLANS_QUERY, DOCTOR_APPOINTMENT_QUERY } from './queries';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowForward from '@mui/icons-material/ArrowForward';
import DashboardOverview from './Components/DashboardOverview';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import {
  calcAdherencePercentage,
  calcAverageDailyPlans,
  calcAverageFractions,
  calcAverageSimToTreat,
  calcComplexTechnique,
  calcTotalDiagnosis,
  calcTotalPlans,
  calcTotalReferrals,
  calcAverageDailyTreatmentsPerLinac,
  calcTotalSimulations,
} from './Utils/dataFunctions';
import * as Config from './Components/DashboardConfigs';
import { Typography, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';

LayoutModule(Dashboards);
exporting(Highcharts);
exportData(Highcharts);
Dashboards.HighchartsPlugin.custom.connectHighcharts(Highcharts);
Dashboards.DataGridPlugin.custom.connectDataGrid(DataGrid);
Dashboards.PluginHandler.addPlugin(Dashboards.HighchartsPlugin);
Dashboards.PluginHandler.addPlugin(Dashboards.DataGridPlugin);

Highcharts.setOptions({
  lang: {
    contextButtonTitle: 'Download data',
  },
});

Highcharts.SVGRenderer.prototype.symbols.download = function (x: any, y: any, w: any, h: any) {
  return [
    // Rectangle (Bottom Bar)
    'M',
    x + w * 0.22,
    y + h * 0.83,
    'L',
    x + w * 0.78,
    y + h * 0.83,
    'L',
    x + w * 0.78,
    y + h * 0.75,
    'L',
    x + w * 0.22,
    y + h * 0.75,
    'Z',

    // Arrow Shape
    'M',
    x + w * 0.78,
    y + h * 0.375,
    'L',
    x + w * 0.62,
    y + h * 0.375,
    'L',
    x + w * 0.62,
    y + h * 0.125,
    'L',
    x + w * 0.38,
    y + h * 0.125,
    'L',
    x + w * 0.38,
    y + h * 0.375,
    'L',
    x + w * 0.22,
    y + h * 0.375,
    'L',
    x + w * 0.5,
    y + h * 0.67,
    'Z',
  ];
};

type SectionNames = 'Quality' | 'Access' | 'Efficiency' | 'Activity';

const qualityNavItemsMapping: { [key: string]: { name: string; enabled: boolean } } = {
  carepathway: {
    name: 'Carepathway Utilization',
    enabled: CurrentAppConfig.Insights?.Dashboards.careplanAdherence ?? false,
  },
  techniques: { name: 'Technique Utilization', enabled: CurrentAppConfig.Insights?.Dashboards.techniques ?? false },
  fractions: {
    name: 'Fractionation Schedule',
    enabled: CurrentAppConfig.Insights?.Dashboards.averageFractions ?? false,
  },
};

const accessNavItemsMapping: { [key: string]: { name: string; enabled: boolean } } = {
  referrers: { name: 'Top 10 Referrers', enabled: CurrentAppConfig.Insights?.Dashboards.totalReferrals ?? false },
  diagnosis: { name: 'Diagnosis', enabled: CurrentAppConfig.Insights?.Dashboards.totalDiagnosis ?? false },
  activity: { name: 'Clinical Activity', enabled: CurrentAppConfig.Insights?.Dashboards.totalPlans ?? false },
};

const efficiencyNavItemsMapping: { [key: string]: { name: string; enabled: boolean } } = {
  machines: { name: 'Machine Utilization', enabled: CurrentAppConfig.Insights?.Dashboards.linacs ?? false },
  'wait-times': { name: 'Patient Wait Times', enabled: CurrentAppConfig.Insights?.Dashboards.simToTreatment ?? false },
  productivity: {
    name: 'Planning Productivity',
    enabled: CurrentAppConfig.Insights?.Dashboards.averageDailyPlans ?? false,
  },
};

const activityNavItemsMapping: { [key: string]: { name: string; enabled: boolean } } = {
  consultations: {
    name: 'Total Consultations',
    enabled: CurrentAppConfig.Insights?.Dashboards.totalConsultations ?? false,
  },
  simulations: { name: 'Total Simulations', enabled: CurrentAppConfig.Insights?.Dashboards.totalSimulations ?? false },
  treatments: { name: 'Total Treatments', enabled: CurrentAppConfig.Insights?.Dashboards.totalTreatments ?? false },
};

const NavBar = ({
  openSections,
  toggleSection,
  navToPage,
  currentPage,
  theme,
}: {
  openSections: any;
  toggleSection: any;
  navToPage: any;
  currentPage: any;
  theme: any;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Stack
      height="100%"
      width="240px"
      min-width="240px"
      borderRight="rgba(0, 0, 0, 0.2) 1px solid"
      sx={{ backgroundColor: '#FAFAFA' }}>
      <button
        onClick={() => navToPage('index')}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          backgroundColor: isHovered ? '#efefef' : '#FAFAFA',
          border: 'none',
          fontSize: '16px',
          marginBottom: '10px',
          cursor: 'pointer',
          textAlign: 'left',
          width: '100%',
          padding: '20px 0 12px 15px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        }}>
        <Stack direction="row" alignItems="center" marginBottom={1}>
          <ArrowForward
            style={{
              marginRight: '15px',
              transform: 'rotate(180deg)',
              opacity: '54%',
              color: theme.palette.primary.dark,
            }}
          />
          <Stack>
            <Typography>Back</Typography>
            <Typography variant="subtitle2" color={theme.palette.text.secondary} sx={{ fontWeight: 400 }}>
              Insights Dashboard
            </Typography>
          </Stack>
        </Stack>
      </button>
      <Stack>
        <Stack>
          <Typography
            variant="subtitle1"
            onClick={() => toggleSection('Activity')}
            display="flex"
            marginTop="10px"
            paddingLeft="10px"
            alignItems="center"
            sx={{ cursor: 'pointer' }}>
            <ExpandMore style={{ transform: `rotate(${openSections.Activity ? 0 : '-90deg'})` }} />
            Activity
          </Typography>
          {openSections.Activity && (
            <NavList navToPage={navToPage} currentPage={currentPage} navItems={activityNavItemsMapping} />
          )}
        </Stack>
        <Stack>
          <Typography
            variant="subtitle1"
            onClick={() => toggleSection('Quality')}
            display="flex"
            marginTop="10px"
            paddingLeft="10px"
            alignItems="center"
            sx={{ cursor: 'pointer' }}>
            <ExpandMore style={{ transform: `rotate(${openSections.Quality ? 0 : '-90deg'})` }} />
            Quality
          </Typography>
          {openSections.Quality && (
            <NavList navToPage={navToPage} currentPage={currentPage} navItems={qualityNavItemsMapping} />
          )}
        </Stack>
        <Typography
          variant="subtitle1"
          onClick={() => toggleSection('Access')}
          display="flex"
          marginTop="10px"
          paddingLeft="10px"
          alignItems="center"
          sx={{ cursor: 'pointer' }}>
          <ExpandMore style={{ transform: `rotate(${openSections.Access ? 0 : '-90deg'})` }} />
          Access
        </Typography>
        {openSections.Access && (
          <NavList navToPage={navToPage} currentPage={currentPage} navItems={accessNavItemsMapping} />
        )}
        <Stack>
          <Typography
            variant="subtitle1"
            onClick={() => toggleSection('Efficiency')}
            display="flex"
            marginTop="10px"
            paddingLeft="10px"
            alignItems="center"
            sx={{ cursor: 'pointer' }}>
            <ExpandMore style={{ transform: `rotate(${openSections.Efficiency ? 0 : '-90deg'})` }} />
            Efficiency
          </Typography>
          {openSections.Efficiency && (
            <NavList navToPage={navToPage} currentPage={currentPage} navItems={efficiencyNavItemsMapping} />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const NavList = ({
  navToPage,
  currentPage,
  navItems,
}: {
  navToPage: (page: string) => void;
  currentPage: string;
  navItems: { [key: string]: { name: string; enabled: boolean } };
}) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  return (
    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
      {Object.entries(navItems).map(([label, value]) => {
        const isActive = currentPage === label;
        const isDisabled = !value.enabled;
        const isHovered = hoveredItem === label && !isDisabled;
        const showTooltip = hoveredItem === label && isDisabled;

        const styles = {
          padding: '12px 0',
          backgroundColor: isActive ? '#CDC6FE' : isHovered ? '#efefef' : 'transparent',
          borderRadius: isActive ? '4px' : 'none',
          borderLeft: isActive ? '4px solid #081E43' : 'none',
          cursor: isDisabled ? 'default' : 'pointer',
          color: isDisabled ? 'rgba(8, 30, 67, 0.38)' : 'inherit',
          fontSize: '16px',
          fontWeight: '400',
          paddingLeft: '15px',
        };

        return (
          <Tooltip
            title="Coming soon! Preview it now on the insights dashboard page"
            open={showTooltip}
            placement="right"
            arrow
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -30],
                  },
                },
              ],
            }}>
            <li
              key={label}
              onClick={() => !isDisabled && navToPage(label)}
              onMouseEnter={() => setHoveredItem(label)}
              onMouseLeave={() => setHoveredItem(null)}
              style={styles}>
              {value.name}
            </li>
          </Tooltip>
        );
      })}
    </ul>
  );
};

// TODO: change this to use start date from the date range picker
const startDate = new Date(2024, 8, 1);
const endDate = new Date();

const InsightsDashboard = () => {
  const history = useHistory();
  const theme = useTheme();

  const [dashboardData, setDashboardData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState('index');

  const [openSections, setOpenSections] = useState<Record<SectionNames, boolean>>({
    Quality: true,
    Access: true,
    Efficiency: true,
    Activity: true,
  });

  const navToPage = (page: string) => {
    if (currentPage === page) {
      return;
    }

    if (page === 'index') {
      history.push('/radiation/insights');
    } else {
      history.push(`/radiation/insights/${page}`);
    }
    setCurrentPage(page);
  };

  useEffect(() => {
    const baseUrl = '/radiation/insights';

    const pageMapping: { [key: string]: string } = {
      baseUrl: 'index',
      [`${baseUrl}/carepathway`]: 'carepathway',
      [`${baseUrl}/techniques`]: 'techniques',
      [`${baseUrl}/fractions`]: 'fractions',
      [`${baseUrl}/referrers`]: 'referrers',
      [`${baseUrl}/diagnosis`]: 'diagnosis',
      [`${baseUrl}/activity`]: 'activity',
      [`${baseUrl}/machines`]: 'machines',
      [`${baseUrl}/wait-times`]: 'wait-times',
      [`${baseUrl}/productivity`]: 'productivity',
      [`${baseUrl}/consultations`]: 'consultations',
      [`${baseUrl}/simulations`]: 'simulations',
      [`${baseUrl}/treatments`]: 'treatments',
    };

    setCurrentPage(pageMapping[location.pathname] || 'index');
    restoreScrollPosition(0);
    scrollPositionRef.current = 0;

    const unlisten = history.listen((location) => {
      setCurrentPage(pageMapping[location.pathname] || 'index');
    });

    return () => {
      unlisten();
    };
  }, [history, location.pathname]);

  const toggleSection = (section: SectionNames) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section],
    }));
  };

  const tableWrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollPositionRef = useRef<number>(0);

  const saveScrollPosition = useCallback(() => {
    if (tableWrapperRef.current && tableWrapperRef.current.scrollTop !== 0) {
      scrollPositionRef.current = tableWrapperRef.current.scrollTop;
    }
  }, []);

  const restoreScrollPosition = (override?: number) => {
    if (tableWrapperRef.current) {
      requestAnimationFrame(() => {
        if (override || override === 0) {
          tableWrapperRef.current!.scrollTop = override;
        } else {
          tableWrapperRef.current!.scrollTop = scrollPositionRef.current;
        }
      });
    }
  };

  useEffect(() => {
    const tableWrapper = tableWrapperRef.current;
    if (tableWrapper) {
      tableWrapper.addEventListener('scroll', saveScrollPosition);
    }

    return () => {
      if (tableWrapper) {
        tableWrapper.removeEventListener('scroll', saveScrollPosition);
      }
    };
  }, [saveScrollPosition]);

  const { data: careplanData, error } = useQuery(GET_CAREPLANS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      rowsPerPage: 9999,
      status: ['Plan Aim', 'Prescription', 'Prescription Directive'],
      onlyForRequestingPractitioner: false,
    },
  });

  const { data: appointmentData, error: appointmentError } = useQuery(DOCTOR_APPOINTMENT_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      startDate: startDate,
      endDate: endDate,
      appointmentTypes: ['Sim'],
      excludeCancelled: true,
    },
  });

  useEffect(() => {
    function convertDateFormat(dateStr: string) {
      if (!dateStr) {
        return '';
      }

      const [year, month, day] = dateStr.split('-');
      return `${month}/${day}/${year}`;
    }

    if (CurrentAppConfig.Insights?.mockData) {
      const updatedData = MockData.map((record: { [key: string]: any }) => {
        return {
          ...record,
          submittedAt: convertDateFormat(record.submittedAt),
          __typename: 'CarepathCareplanType',
        };
      });

      setDashboardData(updatedData);
    } else if (careplanData && appointmentData) {
      const excludedDoctors = ['Nick Stock', 'Gary Bee', 'LumonusAI Service', 'demo_ro@lumonus.com'];
      const filteredCareplanData = careplanData['careplans'].filter((record: { [key: string]: any }) => {
        return !excludedDoctors.includes(record.createdBy);
      });

      const processedCareplanData = filteredCareplanData.map((record: { [key: string]: any }) => {
        const siteValues = record.sitegroupSet?.[0]?.siteSet?.[0]?.sitevaluesSet || [];

        const getSiteValue = (name: string, defaultValue: any = '') => {
          return (
            siteValues
              .find((siteValue: { field: { name: string } }) => siteValue.field.name === name)
              ?.value.replace(/"/g, '') || defaultValue
          );
        };

        // grouping similar techniques to reduce the number of categories:
        //    - '3D Conformal ARC', '3D', 'Electrons', 'Simple', 'Clinical' = 'Conformal'
        //    - 'Brachy - HDR', 'Brachy - LDR' and 'Brachy Seeds' = 'Brachy'
        //    - 'SBRT VMAT', 'SBRT - IMRT', 'SRS IMRT', 'SRS VMAT' = 'Stereotactic'
        //    - 'VMAT', 'IMRT' = 'IMRT/VMAT'
        const conformalTechniques = ['3D', '3D Conformal ARC', 'Electrons', 'Simple', 'Clinical'];
        let techniqueValue = getSiteValue('Technique', '').replace(/"/g, '');

        if (techniqueValue.includes('Brachy')) {
          techniqueValue = 'Brachy';
        } else if (conformalTechniques.includes(techniqueValue)) {
          techniqueValue = 'Conformal';
        } else if (techniqueValue.includes('SBRT') || techniqueValue.includes('SRS')) {
          techniqueValue = 'Stereotactic';
        } else if (techniqueValue === 'VMAT' || techniqueValue === 'IMRT') {
          techniqueValue = 'IMRT/VMAT';
        }

        let tumourStreamValue = record.diagnosis?.diagnosisCode?.tumourStream?.name || '';
        if (tumourStreamValue === 'Miscellaneous') {
          tumourStreamValue = 'Misc';
        } else if (tumourStreamValue === 'GI, Colo-rectal + Abdo') {
          tumourStreamValue = 'GI';
        } else if (tumourStreamValue === 'Sarcoma - Soft Tissue') {
          tumourStreamValue = 'Sarcoma';
        }

        const fractionsValue = parseFloat(getSiteValue('Fractions', 0));

        // if there are multiple dose values, take the first one for calculating fraction dose
        const doseValue = parseFloat(getSiteValue('Dose', '0').replace('[', '').replace(']', '').split(',')[0]);

        const diagnosisCodeValue =
          record.diagnosisCode + ' ' + record.diagnosis?.diagnosisCode?.diagnosisDescription || '';

        return {
          ...record,
          createdBy: record.createdBy || 'Unspecified',
          treatingDepartment: record.treatingDepartment || 'NCCC',
          diagnosisCode: diagnosisCodeValue,
          tumourStream: tumourStreamValue,
          technique: techniqueValue,
          fractions: fractionsValue,
          fractionDose: doseValue / fractionsValue,

          // TODO get real values for these once these dashboards are implemented
          adherence: true,
          linac: '',
          sim_to_treat: 0,
          referrer: '',
        };
      });

      const processedAppointmentData = appointmentData['doctorAppointmentsByDateWithPractitioners'].map(
        (record: { [key: string]: any }) => {
          return {
            ...record,
            submittedAt: record.appointment.startTime,
          };
        },
      );

      const mergedAppointmentData = processedAppointmentData.map((record: any) => {
        // assuming that we can match appointments to careplans by joining on patient id
        // this may work for simple sites, but likely need a more robust way to match these moving forward
        const careplan = processedCareplanData.find(
          (careplan: any) => careplan.patient?.id === record.appointment?.patient?.id,
        );

        // assume first related practioner with relationshipType = 'oncologist' is the one assigned to the appointment
        const relatedOncologist = record.relatedPractitioners.find(
          (practitioner: any) => practitioner.relationshipType === 'oncologist',
        );

        const physicianFullName = relatedOncologist
          ? relatedOncologist.practitioner.firstName + ' ' + relatedOncologist.practitioner.lastName
          : 'Unspecified';

        if (careplan) {
          return {
            ...record,
            createdBy: physicianFullName,
            treatingDepartment: careplan.treatingDepartment,
            tumourStream: careplan.tumourStream,
          };
        }

        // if a matching careplan is not found, return the appointment with empty values
        return {
          ...record,
          createdBy: physicianFullName,
          treatingDepartment: 'NCCC', // TODO: get from appointment location instead
          tumourStream: 'Unspecified',
        };
      });

      setDashboardData([...processedCareplanData, ...mergedAppointmentData]);
    }
  }, [careplanData, appointmentData]);

  return (
    <DashboardContextProvider data={dashboardData} navToPage={navToPage} onChartClick={restoreScrollPosition}>
      <Stack direction="row" width="100%" height="100%">
        {currentPage !== 'index' && (
          <NavBar
            openSections={openSections}
            toggleSection={toggleSection}
            navToPage={navToPage}
            currentPage={currentPage}
            theme={theme}
          />
        )}
        <StyledTile sx={{ paddingTop: '20px', paddingBottom: '10px', margin: 0, boxShadow: 'none' }}>
          <Stack
            justifyContent="space-between"
            width="100%"
            height="80%"
            flexGrow={1}
            ref={tableWrapperRef}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              marginRight: '-15px',
              paddingRight: '15px',
              '&::-webkit-scrollbar': {
                width: '8px',
                WebkitAppearance: 'none',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#cfcfcf',
                borderRadius: '8px',
                boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
              },
            }}>
            {currentPage === 'index' && <DashboardOverview />}
            {currentPage === 'carepathway' && (
              <Dashboard
                title="Carepathway Utilization"
                metric="Careplan Adherence"
                metricFunction={calcAdherencePercentage}
                config={Config.CarepathUtilisation}
                metricSuffix="%"
                dataType="CarepathCareplanType"
              />
            )}
            {currentPage === 'techniques' && (
              <Dashboard
                title="Technique Utilization"
                metric="IMRT/VMAT/Stereotactic plans"
                metricFunction={calcComplexTechnique}
                config={Config.TechniqueUtilisation}
                metricSuffix="%"
                dataType="CarepathCareplanType"
              />
            )}
            {currentPage === 'fractions' && (
              <Dashboard
                title="Fractionation Schedule"
                metric="Average Fractions"
                metricFunction={calcAverageFractions}
                metricRound={1}
                config={Config.FractionationSchedule}
                dataType="CarepathCareplanType"
              />
            )}
            {currentPage === 'referrers' && (
              <Dashboard
                title="Top 10 Referrers"
                metric="Total Referrals"
                metricFunction={calcTotalReferrals}
                config={Config.Top10Referrers}
                dataType="CarepathCareplanType"
              />
            )}
            {currentPage === 'diagnosis' && (
              <Dashboard
                title="Diagnosis"
                metric="Total Diagnoses"
                metricFunction={calcTotalDiagnosis}
                config={Config.Diagnosis}
                dataType="CarepathCareplanType"
              />
            )}
            {currentPage === 'activity' && (
              <Dashboard
                title="Clinical Activity"
                metric="Total Plans"
                metricFunction={calcTotalPlans}
                config={Config.ClinicalActivity}
                dataType="CarepathCareplanType"
              />
            )}
            {currentPage === 'machines' && (
              <Dashboard
                title="Machine Utilization"
                metric="Average Daily Treatments Per Linac"
                metricFunction={calcAverageDailyTreatmentsPerLinac}
                config={Config.MachineUtilisation}
                metricRound={1}
                dataType="CarepathCareplanType"
              />
            )}
            {currentPage === 'wait-times' && (
              <Dashboard
                title="Patient Wait Times"
                metric="Average Sim to Treatment Time (Days)"
                metricFunction={calcAverageSimToTreat}
                config={Config.PatientWaitTimes}
                metricRound={1}
                dataType="CarepathCareplanType"
              />
            )}
            {currentPage === 'productivity' && (
              <Dashboard
                title="Planning Productivity"
                metric="Average Daily Plans"
                metricFunction={calcAverageDailyPlans}
                config={Config.PlanningProductivity}
                metricRound={1}
                dataType="CarepathCareplanType"
              />
            )}
            {currentPage === 'simulations' && (
              <Dashboard
                title="Total Simulations"
                metric="Total Simulations"
                metricFunction={calcTotalSimulations}
                config={Config.TotalSimulations}
                dataType="AppointmentWithPractitionersType"
              />
            )}
          </Stack>
        </StyledTile>
      </Stack>
    </DashboardContextProvider>
  );
};

export default InsightsDashboard;
