const COMPLEX_TECHNIQUES = ['IMRT', 'VMAT', 'IMRT/VMAT', 'Stereotactic', 'Complex'];

const sortFunctions: any = {
  days: (a: string, b: string) => a.localeCompare(b),
  weeks: (a: string, b: string) => parseInt(a.split('_')[1]) - parseInt(b.split('_')[1]),
  months: (a: string, b: string) => new Date(a).getTime() - new Date(b).getTime(),
};

const getWeekNumber = (date: Date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  return Math.ceil(((date.getTime() - firstDayOfYear.getTime()) / 86400000 + firstDayOfYear.getDay() + 1) / 7);
};

const findParentById = (element: HTMLElement, id: string) => {
  let currentElement = element;
  while (currentElement !== null && currentElement !== document.body) {
    if (currentElement.id === id) {
      return currentElement;
    }
    currentElement = currentElement.parentElement!;
  }
  return null;
};

const parseDate = (dateStr: string): number => {
  const [month, day, year] = dateStr.split('/').map(Number);
  return Date.UTC(year, month - 1, day);
};

const groupSmallSlicesIntoOther = (data: any[]) => {
  const total = data.reduce((sum, item) => sum + item.y, 0);
  const threshold = total * 0.1;

  const groupedData = [];
  let otherTotal = 0;

  data.forEach((item) => {
    if (item.y < threshold) {
      otherTotal += item.y;
    } else {
      groupedData.push(item);
    }
  });

  if (otherTotal > 0) {
    groupedData.push({
      name: 'Other',
      y: otherTotal,
      color: 'rgba(200, 200, 200)',
      colorIndex: 999,
    });
  }

  return groupedData;
};

const groupCategoriesIntoOther = (data: any[][], threshold = 0.1) => {
  // Validate input
  if (data.length < 2) {
    return data;
  }

  // Extract headers and data rows
  const [headers, ...rows] = data;

  // Initialize totals for each category (excluding submittedAt)
  const categoryTotals: { [key: string]: number } = {};
  let grandTotal = 0;

  // Calculate totals for each category
  headers.forEach((header, index) => {
    if (header !== 'submittedAt') {
      categoryTotals[header] = rows.reduce((sum, row) => sum + row[index], 0);
      grandTotal += categoryTotals[header];
    }
  });

  // Calculate percentages and identify categories to group
  const categoriesToGroup: string[] = [];
  const percentages: { [key: string]: number } = {};

  headers.forEach((header) => {
    if (header !== 'submittedAt') {
      const percentage = categoryTotals[header] / grandTotal;
      percentages[header] = percentage;
      if (percentage < threshold) {
        categoriesToGroup.push(header);
      }
    }
  });

  // If no categories need grouping, return original data
  if (categoriesToGroup.length === 0) {
    return data;
  }

  // Create new headers
  const newHeaders = headers.filter((header) => header === 'submittedAt' || !categoriesToGroup.includes(header));
  newHeaders.push('Other');

  // Create new rows
  const newRows = rows.map((row) => {
    const newRow = row.filter(
      (_, index) => headers[index] === 'submittedAt' || !categoriesToGroup.includes(headers[index]),
    );

    // Calculate sum of grouped categories for this row
    const otherSum = categoriesToGroup.reduce((sum, category) => {
      const index = headers.indexOf(category);
      return sum + row[index];
    }, 0);

    newRow.push(otherSum);
    return newRow;
  });

  return [newHeaders, ...newRows];
};

export {
  COMPLEX_TECHNIQUES,
  sortFunctions,
  getWeekNumber,
  findParentById,
  parseDate,
  groupSmallSlicesIntoOther,
  groupCategoriesIntoOther,
};
