import Highcharts from 'highcharts';
import { syncConfig, creditsConfig, BottomChartParams, loadSeeMoreButton, subExportingConfig } from './DefaultConfig';

const SubChartStackedBarPercent = ({
  title,
  connector,
  cell,
  filterOverride,
  seeMore,
  theme,
  toggleSeeMore,
  dataLength,
}: BottomChartParams): Dashboards.Board.Options => ({
  components: [
    {
      cell: cell,
      connector: {
        id: connector,
        columnAssignment: [
          {
            seriesId: 'series-0',
            data: ['Field', 'Adherence', 'Non-Adherent'],
          },
          {
            seriesId: 'series-1',
            data: ['Field', 'Non-Adherent', 'Adherence'],
          },
        ],
      },
      sync: syncConfig,
      type: 'Highcharts',
      chartOptions: {
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ['downloadCSV', 'downloadXLS'],
              y: -7,
              x: 70,
              symbol: 'download',
              symbolSize: 24,
            },
          },
        },
        chart: {
          type: 'bar',
          spacing: [20, 90, 70, 20],
          height: 320,
          events: {
            load() {
              loadSeeMoreButton(this, toggleSeeMore, seeMore);

              const chart = this as Highcharts.Chart & {
                labelHeader1?: Highcharts.SVGElement;
                labelHeader2?: Highcharts.SVGElement;
              };

              chart.container.classList.add('stacked-bar-percent');

              const positionText = () => {
                const textWidth = 50;
                const xPosition1 = chart.chartWidth / 2 - textWidth - 45;
                const xPosition2 = chart.chartWidth - textWidth - 65;

                if (chart.labelHeader1 && chart.labelHeader2) {
                  chart.labelHeader1.attr({ x: xPosition1 });
                  chart.labelHeader2.attr({ x: xPosition2 });
                } else {
                  chart.labelHeader1 = chart.renderer
                    .text('Careplan adherence', xPosition1, 55)
                    .css({
                      color: theme.palette.text.primary,
                      fontSize: '12px',
                      fontFamily: 'Poppins, sans-serif',
                    })
                    .add();

                  chart.labelHeader2 = chart.renderer
                    .text('Manual careplan', xPosition2, 55)
                    .css({
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                      fontFamily: 'Poppins, sans-serif',
                    })
                    .add();
                }
              };

              positionText();

              chart.redraw = function () {
                Highcharts.Chart.prototype.redraw.call(this);
                positionText();
              };
            },
          },
        },
        title: {
          text: title,
          align: 'left',
        },
        subtitle: {
          text: filterOverride,
          align: 'left',
          style: {
            color: 'rgba(8, 30, 67, 0.60)',
            fontSize: '14px',
          },
        },
        xAxis: {
          type: 'category',
          scrollbar: {
            enabled: true,
          },
        },
        yAxis: {
          reversed: true,
          visible: false,
          max: 200,
          scrollbar: {
            enabled: true,
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        credits: creditsConfig,
        series: [
          {
            id: 'series-0',
            type: 'bar',
            yAxis: 0,
            dataLabels: [
              {
                enabled: true,
                formatter: function () {
                  return (
                    '<span style="font-weight: 400; font-family: Poppins, sans-serif">' + this.y?.toFixed(0) + '</span>'
                  );
                },
                align: 'center',
                verticalAlign: 'middle',
                alignTo: 'plotEdges',
                x: -65,
                y: -1,
                crop: false,
                overflow: 'allow',
              },
              {
                enabled: true,
                formatter: function () {
                  const percentage = (this.y && this.total ? (this.y / this.total) * 100 : 0).toFixed(0);
                  return (
                    '<span style="color: #696969; font-weight: 400; font-family: Poppins, sans-serif">(' +
                    percentage +
                    '%' +
                    ')</span>'
                  );
                },
                align: 'center',
                verticalAlign: 'middle',
                alignTo: 'plotEdges',
                x: -25,
                y: -1,
                crop: false,
                overflow: 'allow',
              },
            ],
          },
          {
            id: 'series-1',
            type: 'bar',
            yAxis: 0,
            dataLabels: [
              {
                enabled: true,
                formatter: function () {
                  return (
                    '<span style="font-weight: 400; font-family: Poppins, sans-serif">' + this.y?.toFixed(0) + '</span>'
                  );
                },
                align: 'right',
                verticalAlign: 'middle',
                alignTo: 'plotEdges',
                x: 25,
                y: -1,
                crop: false,
                overflow: 'allow',
              },
              {
                enabled: true,
                formatter: function () {
                  const percentage = (this.y && this.total ? (this.y / this.total) * 100 : 0).toFixed(0);
                  return (
                    '<span style="color: #696969; font-weight: 400; font-family: Poppins, sans-serif">(' +
                    percentage +
                    '%' +
                    ')</span>'
                  );
                },
                align: 'right',
                verticalAlign: 'middle',
                alignTo: 'plotEdges',
                x: 70,
                y: -1,
                crop: false,
                overflow: 'allow',
              },
            ],
          },
        ],
        plotOptions: {
          bar: {
            borderRadius: 8,
            pointWidth: 9,
            stacking: 'percent',
          },
          series: {
            cursor: 'pointer',
          },
        },
      },
    },
  ],
});

export default SubChartStackedBarPercent;
