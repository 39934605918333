import { gql, useQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { WithApolloClient, withApollo } from '@apollo/client/react/hoc';
import { FormStatus, FormType, RoleType } from 'op-enums';
import { Component, FormEvent, Fragment, useEffect } from 'react';
import { styled } from '@mui/system';
import { Typography, Button, Stack } from '@mui/material';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import * as Sentry from '@sentry/browser';
import 'url-search-params-polyfill';
import { CurrentAppConfig } from 'op-pages/RO/Careplan/AppConfig';
import { GET_TAG_OPTIONS } from 'op-components/PatientCard/PatientCardQueries';
import { RECORD_LOCK_QUERY, RELEASE_LOCK_MUTATION } from 'op-graphql';
import { Patient, PatientOptIn, PatientSearchQueryItem, PatientSearchQueryItemAddress } from 'op-interfaces';
import { Region } from 'shared-components/enums';
import { GraphUpdate, ReactListData } from 'shared-components/interfaces';
import { DateTimeConverter, Logger, navigateToExternalURL } from 'shared-components/utils';
import './PatientSearch.scss';
import {
  CREATE_SUBMISSION,
  GET_USER_DETAILS,
  LOGOUT_MUTATION,
  PATIENT_SEARCH,
  SEARCH_FILTERS_REF_DATA_QUERY,
  SEND_PX_EMAIL_INVITATION,
} from './PatientSearchQueries';

import { ModalInfo, ModalOptIn, SearchFilter, SearchMultiSelectField } from 'op-components';
import { LoadingSpinner, StandardDialog } from 'shared-components/components';

import PatientAccessModal from 'op-components/Modals/PatientAccessModal/PatientAccessModal';
import { NavigationContext } from 'op-contexts';
import { isUs } from 'op-utils/helpers';
import { ModalContentSubmissionError } from 'shared-components/components';
import { BulletPoint, EMROutlined, ErrorCross, Success } from 'shared-components/images';
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';
import { COPY } from './constants';
import { LoadingButton } from '@mui/lab';

const { PSO, SUPERUSER, NURSE } = RoleType;

const logger = new Logger('PatientSearch');

const SEARCH_NAME = 'q';
const EMPTY_PATIENT_OPT_IN = {
  id: '',
  firstName: '',
  lastName: '',
  pxOptedIn: null,
  primaryPhone: '',
  email: '',
  pxOptOutComment: '',
  emrInstance: '',
};
const EMPTY_PATIENT_ADDRESS = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  country: '',
  postcode: '',
};

const EMPTY_PATIENT = {
  id: '',
  firstName: '',
  lastName: '',
  ida: '',
  dob: '',
  primaryPhone: '',
  primaryPhoneFormatted: '',
  email: '',
  primaryCenterAlias: '',
  address: EMPTY_PATIENT_ADDRESS,
  pxOptedIn: null,
  pxOptOutComment: '',
  regFormStatus: '',
  overallStatus: '',
  userProfile: {
    id: '',
    registrationAccessType: '',
    showRegistration: true,
    showHealthAssessment: true,
    patientFeatures: {
      distressThermometerEotEnabled: false,
      distressThermometerEnabled: false,
      managementPlansEnabled: false,
      covidEnabled: false,
    },
  },
  horizonCenterId: '',
  tags: [],
  emrInstance: '',
};

// Keys for local storage
const REGISTRATIONS = 'registrations';
const LOCATIONS = 'locations';
const TAGS = 'tags';

const StyledSearchInput = styled('input')`
  max-width: 872px;
  flex: 1;
  font-size: 16px;
  line-height: get-line-height(16, 24);
  padding: 12px 24px 12px 48px;
  background-color: ${(props) => props.theme.palette.grey[100]};
  border: 2px solid ${(props) => props.theme.palette.grey[600]};
  margin-right: 16px;

  &:focus {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    outline: none;
  }
`;

const StyledPatientSearchResult = styled('div')`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  padding: 16px 8px 24px 8px;
`;

const StyledEmrText = styled('div')`
  font-style: normal;
  font-size: 16px;
  color: ${(props) => props.theme.palette.grey[600]};
`;

const StyledTagStatus = styled('span')`
  border-radius: 100px;
  font-size: 12px;
  padding: 3px 14px;
  white-space: nowrap;
  overflow: hidden;
  border: 2px solid ${(props) => props.theme.palette.tag.main};
  margin-right: 5px;
`;

const StyledPatientSearchTips = styled('div')`
  color: ${(props) => props.theme.palette.grey[600]};
  padding-left: 8px;
  padding-bottom: 72px;
  padding-top: 24px;
`;

export interface PatientSearchQueryResultData {
  searchPatients: PatientSearchQueryItem[];
  user?: {
    features: {
      DT: boolean;
    };
  };
}

interface SearchFiltersQueryResultData {
  searchFiltersRefData: ReactListData[];
}

interface State {
  registrationInviteModalOpen: boolean;
  modalPatientRegAcces: boolean;
  modalHeader?: string;
  selectedPIN: string;
  selectedName: string;
  buttonLoadingId: string;
  selectedPatientOptIn: PatientOptIn;
  optInModalOpen: boolean;
  optInModalInDOM: boolean;
  optInModalLoadingId: string;
  selectedPatient: PatientSearchQueryItem;
  selectedPatientId: string;
  selectedPatientIda: string;
  selectedDob: string;
  selectedAddress: PatientSearchQueryItemAddress;
  lockErrorModalOpen: boolean;
  lockErrorText: string;
  distressThermometerEnabled: boolean;
  allowedLocations: {
    alias: string;
    id: string;
  }[];
  tagOptions: {
    id: string;
    name: string;
  }[];
  primaryRole: string;
  selectedLocations: string[];
  selectedTags: string[];
  selectedRegistrations: string[];
  formType: string;
  profileSlideOpen: boolean;
  pageShown: string;
  isPractitioner: boolean;
  isOptInMutationLoading: boolean;
}

interface UserDetailsResponse {
  data: {
    user: {
      id: string;
      username: string;
      features: {
        distressThermometer: boolean;
      };
      primaryRole: string;
      isPractitioner: boolean;
    };
    allowedLocations: {
      alias: string;
      id: string;
    }[];
  };
}

interface Props extends RouteComponentProps, WithApolloClient<{}> {}

class PatientSearch extends Component<Props, State> {
  public static contextType = NavigationContext;
  private searchTerm?: string = undefined;
  private liveSearchInput: HTMLInputElement | null = null;
  private triggerSubmit: HTMLFormElement | null = null;

  public constructor(props: Props) {
    super(props);
    this.state = {
      registrationInviteModalOpen: false,
      modalPatientRegAcces: false,
      modalHeader: undefined,
      selectedName: '',
      selectedPIN: '',
      buttonLoadingId: '',
      optInModalLoadingId: '',
      optInModalOpen: false,
      optInModalInDOM: false,
      selectedPatient: EMPTY_PATIENT,
      selectedPatientId: '',
      selectedPatientIda: '',
      selectedDob: '',
      selectedAddress: { ...EMPTY_PATIENT_ADDRESS },
      lockErrorModalOpen: false,
      lockErrorText: '',
      selectedPatientOptIn: { ...EMPTY_PATIENT_OPT_IN },
      distressThermometerEnabled: false,
      allowedLocations: [],
      tagOptions: [],
      primaryRole: '',
      selectedLocations: JSON.parse(sessionStorage.getItem(LOCATIONS) || '[]'),
      selectedTags: JSON.parse(sessionStorage.getItem(TAGS) || '[]'),
      selectedRegistrations: JSON.parse(sessionStorage.getItem(REGISTRATIONS) || '[]'),
      formType: '',
      profileSlideOpen: false,
      pageShown: 'search',
      isPractitioner: true,
      isOptInMutationLoading: false,
    };
    this.setFormType = this.setFormType.bind(this);
  }

  public componentDidMount(): void {
    const { client } = this.props;
    if (!client) return;
    client
      .query({ query: GET_USER_DETAILS, variables: { roles: ['PSO'], hasOther: true } })
      .then((results: UserDetailsResponse): any => {
        {
          const scope = Sentry.getCurrentScope();
          scope.setTag('user_type', 'pso');
          scope.setUser({
            id: results.data.user.id,
            username: results.data.user.username,
          });
        }

        this.setState({
          distressThermometerEnabled: results.data.user.features.distressThermometer,
          allowedLocations: results.data.allowedLocations,
          primaryRole: results.data.user.primaryRole,
          isPractitioner: results.data.user.isPractitioner,
        });
      });

    // If we've landed on search page. Reset any cache for viewed pages.
    client.writeQuery({
      query: gql`
        query {
          registrationPagesViewed
        }
      `,
      data: {
        registrationPagesViewed: [],
      },
    });
  }

  /**
   * Extract what the search term param is
   */
  public extractSearchTerm = (): void => {
    const searchParams = this.props.location.search;

    if (searchParams) {
      const urlSearchParams = new URLSearchParams(searchParams);
      const query = urlSearchParams.get(SEARCH_NAME);
      if (query) {
        this.searchTerm = query;
        //@ts-ignore
        this.context.setSearchTerm(this.searchTerm);
      }
    } else {
      //@ts-ignore
      this.searchTerm = this.context.state.searchTerm;
    }
  };

  private filterOrTermSelected = (): boolean =>
    Boolean(this.searchTerm) ||
    Boolean(this.liveSearchInput && this.liveSearchInput.value) ||
    Boolean(this.state.selectedRegistrations.length) ||
    Boolean(this.state.selectedLocations.length) ||
    Boolean(this.state.selectedTags.length);

  public render(): JSX.Element {
    const { client } = this.props;

    this.extractSearchTerm();

    const psoOrSuperuser = [PSO, SUPERUSER].includes(this.state.primaryRole);
    const nurse = this.state.primaryRole === NURSE;

    if (!this.state.isPractitioner && [NURSE].includes(this.state.primaryRole)) {
      const isPractitionerError = COPY.IS_PRACTITIONER_ERROR;
      const supportEmail = CurrentAppConfig.SupportEmail;
      const generateEmail = () => {
        window.location.href = `mailto:${supportEmail}`;
      };
      const logout = (): void => {
        if (!client) return;
        client.clearStore().then((): void => {
          client.writeQuery({
            query: gql`
              query {
                contentShown
              }
            `,
            data: {
              contentShown: false,
            },
          });
        });
        client
          .mutate({
            mutation: LOGOUT_MUTATION,
            variables: {},
          })
          .then((response): void => {
            if (response.data.logout.errors !== null) {
              logger.error('logout', 'Unable to logout');
              logger.error('logout', response.data.logout.errors);
              return;
            }
            navigateToExternalURL('/sso/logout');
          });
      };
      return (
        <StandardDialog
          open
          title={COPY.CANT_FIND_ACC_DETAILS}
          submitText={'Logout & contact us'}
          onSubmit={(): void => {
            generateEmail();
            logout();
          }}>
          <ModalContentSubmissionError text={isPractitionerError} emailLink={supportEmail} />
        </StandardDialog>
      );
    }

    return (
      <Query<SearchFiltersQueryResultData> query={SEARCH_FILTERS_REF_DATA_QUERY}>
        {({ loading, error, data }): JSX.Element => {
          const { data: tagOptions, loading: tagOptionsLoading } = useQuery(GET_TAG_OPTIONS);

          const registrationOptions = data?.searchFiltersRefData?.map((reg: { name: string; appKey: string }) => ({
            label: isUs() && reg.name === COPY.SUBMITTED_TO_MOSAIQ ? 'Submitted' : reg.name,
            value: reg.appKey,
          }));
          const locationOptions = this.state.allowedLocations?.map((loc: { alias: string; id: string }) => ({
            label: loc.alias,
            value: loc.id,
          }));
          const tagFilterOptions = tagOptions?.tagOptions?.map((tag: { name: string; id: string }) => ({
            label: tag.name,
            value: tag.id,
          }));

          const { selectedRegistrations, selectedTags, selectedLocations } = this.state;

          const setSelectedRegistrations = (selectedRegistrations: string[]): void =>
            this.setState({ selectedRegistrations });
          const setSelectedLocations = (selectedLocations: string[]): void => this.setState({ selectedLocations });
          const setSelectedTags = (selectedTags: string[]): any => this.setState({ selectedTags });

          useEffect(() => {
            sessionStorage.setItem(REGISTRATIONS, JSON.stringify(selectedRegistrations));
          }, [selectedRegistrations]);

          useEffect(() => {
            sessionStorage.setItem(LOCATIONS, JSON.stringify(selectedLocations));
          }, [selectedLocations]);

          useEffect(() => {
            sessionStorage.setItem(TAGS, JSON.stringify(selectedTags));
          }, [selectedTags]);

          if (loading || tagOptionsLoading) {
            return <LoadingSpinner />;
          }

          return (
            <>
              <div className={`patient-search ${psoOrSuperuser && 'below-pso-dashboard'}`}>
                {nurse && <div className="search-title padded">{'Patient Search'}</div>}
                {(psoOrSuperuser || nurse) && (
                  <form
                    action="/search"
                    id="patient-search-form"
                    method="GET"
                    onSubmit={this.submitHandler}
                    ref={(ref): void => {
                      this.triggerSubmit = ref;
                    }}>
                    <div className="patient-search-input-container">
                      <StyledSearchInput
                        type="text"
                        id="patient-search-input"
                        data-testid="patient-search-input"
                        name={SEARCH_NAME}
                        placeholder={COPY.SEARCH_PLACEHOLDER || ''}
                        defaultValue={this.searchTerm}
                        ref={(ref): void => {
                          this.liveSearchInput = ref;
                        }}
                      />
                      <Button size="large" data-testid="patient-search" variant="contained" type="submit">
                        Search
                      </Button>
                    </div>
                    <Stack
                      direction="row"
                      spacing={2}
                      id={psoOrSuperuser ? 'patient-search-filter-wrapper-pso' : 'patient-search-filter-wrapper-nurse'}>
                      <SearchFilter
                        showAlert={Boolean(
                          selectedRegistrations.length || selectedLocations.length || selectedTags.length,
                        )}>
                        {!nurse && (
                          <div className="search-menu-item">
                            <SearchMultiSelectField
                              selectAllEnabled={true}
                              selectAllLabel="Select all"
                              selectedOptionsUpdated={setSelectedRegistrations}
                              resetSearchResults={() => {}}
                              defaultSelectedOptions={selectedRegistrations}
                              options={registrationOptions || []}
                              placeholder="Registration"
                              allSelected={selectedRegistrations?.length === registrationOptions?.length}
                              allSelectedLabel="All registration types"
                            />
                          </div>
                        )}
                        <div className="search-menu-item">
                          <SearchMultiSelectField
                            selectAllEnabled={true}
                            selectAllLabel="Select all"
                            selectedOptionsUpdated={setSelectedLocations}
                            resetSearchResults={() => {
                              return null;
                            }}
                            defaultSelectedOptions={selectedLocations}
                            options={locationOptions}
                            placeholder="Location"
                            allSelected={selectedLocations?.length === locationOptions?.length}
                            allSelectedLabel="All locations"
                          />
                        </div>
                        {!isUs() && (
                          <div className="search-menu-item">
                            <SearchMultiSelectField
                              selectAllEnabled={true}
                              selectAllLabel="Select all"
                              selectedOptionsUpdated={setSelectedTags}
                              resetSearchResults={() => {
                                return null;
                              }}
                              defaultSelectedOptions={selectedTags}
                              options={tagFilterOptions}
                              placeholder="Tag"
                              allSelected={selectedTags?.length === tagFilterOptions?.length}
                              allSelectedLabel="All tags"
                            />
                          </div>
                        )}
                      </SearchFilter>
                      {(psoOrSuperuser || (nurse && this.state.allowedLocations.length > 0)) && (
                        <Button
                          variant="text"
                          data-testid="clear-filter"
                          onClick={(): void => {
                            setSelectedRegistrations([]);
                            setSelectedLocations([]);
                            setSelectedTags([]);
                          }}>
                          Clear filters
                        </Button>
                      )}
                    </Stack>
                  </form>
                )}
                <br />
              </div>
              {this.filterOrTermSelected() && this.fetchSearchResults()}
            </>
          );
        }}
      </Query>
    );
  }

  public setFormType = (form: string): void => {
    this.setState({ formType: form });
    this.handleGetPINModalOpen(this.state.selectedPatient, form);
  };

  private castToNumber = (stringArray: string[]): number[] => {
    return stringArray.map((option) => parseInt(option));
  };

  private fetchSearchResults = (): JSX.Element => {
    if (!this.filterOrTermSelected()) {
      return <Fragment />;
    }
    return (
      <Query<PatientSearchQueryResultData>
        query={PATIENT_SEARCH}
        variables={{
          searchTerm: this.searchTerm || '',
          selectedFilter: this.state.selectedRegistrations,
          locationFilter: this.castToNumber(this.state.selectedLocations), // Front end needs string[] but backend needs number[] for query join
          tagFilter: this.castToNumber(this.state.selectedTags),
        }}>
        {({ loading, error, data }): JSX.Element => {
          const queryData = data;
          const queryError = error;
          if (loading) {
            return <LoadingSpinner />;
          }

          if (queryError) {
            this.renderNoSearchResults();
          }

          const {
            registrationInviteModalOpen,
            selectedPatient,
            selectedPatientOptIn,
            selectedPatientIda,
            selectedAddress,
            selectedDob,
            lockErrorModalOpen,
            optInModalInDOM,
          } = this.state;

          return (
            <div>
              {registrationInviteModalOpen && (
                <PatientAccessModal
                  patient={selectedPatient}
                  isOpen={registrationInviteModalOpen}
                  onClose={(): void => {
                    this.setState({
                      ...this.state,
                      registrationInviteModalOpen: false,
                    });
                  }}
                />
              )}

              {optInModalInDOM && (
                <ModalOptIn
                  key={`modal-opt-in-${selectedPatientOptIn?.id}`}
                  patient={selectedPatientOptIn}
                  isOpen={this.state.optInModalOpen}
                  dismissFunction={(): void => {
                    if (!this.state.isOptInMutationLoading) {
                      this.releaseLock();
                      // Reset to the default state
                      this.resetOptInModalStates();
                    }
                  }}
                  saveFunction={(graphData: GraphUpdate[], optedIn: boolean, shouldSubmit: boolean): void => {
                    this.handleSaveOptIn(graphData, optedIn, shouldSubmit);
                  }}
                  dismissLock={() => this.setState({ isOptInMutationLoading: false })}
                  setMutationLoading={() => this.setState({ isOptInMutationLoading: true })}
                  patientIda={selectedPatientIda}
                  address={selectedAddress}
                  dob={selectedDob}
                />
              )}
              <ModalInfo
                title={'Record in use'}
                text={this.state.lockErrorText}
                isOpen={lockErrorModalOpen}
                dismissFunction={(): void => {
                  this.setState({
                    lockErrorModalOpen: false,
                  });
                }}
              />
              <StyledPatientSearchResult id="patient-search-results">
                {this.filterOrTermSelected() && this.renderContents(queryData)}
                {this.filterOrTermSelected() &&
                  this.renderSearchTips(
                    Boolean(queryData && queryData.searchPatients && queryData.searchPatients.length > 0),
                    Boolean(this.searchTerm),
                  )}
              </StyledPatientSearchResult>
            </div>
          );
        }}
      </Query>
    );
  };

  private renderContents = (
    data: PatientSearchQueryResultData | undefined,
  ): JSX.Element | JSX.Element[] | undefined => {
    return data && data.searchPatients && data.searchPatients.length
      ? this.renderSearchResults(data)
      : this.renderNoSearchResults();
  };

  private renderSearchResults = (data: PatientSearchQueryResultData): JSX.Element[] | undefined => {
    const psoOrSuperuser = [PSO, SUPERUSER].includes(this.state.primaryRole);
    const isNurse = this.state.primaryRole === NURSE;
    if (data && data.searchPatients && data.searchPatients.length > 0) {
      const searchResults = data.searchPatients
        .filter((patient) => Boolean(patient.ida))
        .map((patient: PatientSearchQueryItem, index: number): JSX.Element => {
          const managementPlansEnabled = patient.userProfile.patientFeatures.managementPlansEnabled;
          let displayClass = '';
          let message = '';
          switch (patient.overallStatus) {
            case FormStatus.REG_SUBMITTED:
              displayClass = 'submitted-to-mq';
              message = isUs() ? 'Submitted' : 'Submitted to MQ';
              break;
            case FormStatus.REG_REVIEW_REQUIRED:
              displayClass = 'needs-review';
              message = 'Review required';
              break;
            case FormStatus.IN_PROGRESS:
              displayClass = 'in-progress';
              message = 'In progress';
              break;
            default:
              displayClass = '';
              message = '';
              break;
          }
          return (
            <div key={index} id={`patient_${patient.ida}`} className="patient-search-result">
              <div className="patient-heading">
                <div className="patient-search-name">
                  {patient.firstName} {patient.lastName}
                </div>
                {psoOrSuperuser &&
                  patient.tags.map((tagItem: any, tagIndex: any): JSX.Element => {
                    return (
                      <StyledTagStatus className="tag-status" key={tagIndex}>
                        {tagItem.tag.name}
                      </StyledTagStatus>
                    );
                  })}
                {psoOrSuperuser && <span className={`form-status ${displayClass}`}>{message}</span>}
              </div>
              <div className="patient-search-emr">
                <EMROutlined />
                <StyledEmrText className="patient-search-emr-text">EMR: {patient.emrInstance || '-'}</StyledEmrText>
              </div>
              <div className="patient-search-details">
                <div className="patient-search-all">
                  {this.renderContentDetails('Patient ID', patient.ida)}
                  {this.renderContentDetails(
                    'Date of birth',
                    DateTimeConverter.getFormattedDateAsDDMonthYYYY(
                      patient.dob,
                      import.meta.env.REACT_APP_REGION as Region,
                    ),
                  )}
                  {this.renderContentDetails('Phone', patient.primaryPhoneFormatted)}
                </div>
                <div className="patient-search-address">
                  {this.renderContentDetails('Address', this.createAddress(patient.address))}
                </div>
                <>
                  {psoOrSuperuser && (
                    <>
                      <div className="patient-get-pin">
                        <LoadingButton
                          variant="outlined"
                          size="large"
                          type="submit"
                          data-testid="form-access-button"
                          onClick={(e): void => {
                            e.preventDefault();
                            this.handleGetPINModalOpen(patient);
                          }}
                          loading={patient.id === this.state.buttonLoadingId}>
                          Form access
                        </LoadingButton>
                        {this.renderRegistrationSignUpStatus(patient)}
                      </div>
                      {!isUs() && (
                        <div className="patient-search-opt-in">
                          <LoadingButton
                            variant="outlined"
                            size="large"
                            data-testid="patient-search-portal-access"
                            onClick={(e): void => {
                              e.preventDefault();
                              this.handleOptInModalOpen(patient);
                            }}
                            loading={patient.id === this.state.optInModalLoadingId}>
                            Portal access
                          </LoadingButton>
                          {this.renderPXSignUp(patient.pxOptedIn)}
                        </div>
                      )}
                    </>
                  )}
                  <div>
                    <Button
                      variant="outlined"
                      size="large"
                      type="submit"
                      sx={{ marginLeft: 2 }}
                      data-testid="patient-form-summary"
                      onClick={(e): void => {
                        e.preventDefault();
                        this.props.history.push({
                          pathname: (psoOrSuperuser ? '/navigator/' : '/') + `patient/${patient.id}/summary`,
                          state: { primaryRole: this.state.primaryRole },
                        });
                      }}>
                      {psoOrSuperuser ? 'View summary' : 'Assessments'}
                    </Button>
                  </div>
                  {isNurse && managementPlansEnabled && (
                    <div className="management-plans-button" style={{ marginLeft: '48px' }}>
                      <Button
                        variant="outlined"
                        size="large"
                        type="submit"
                        onClick={(e): void => {
                          e.preventDefault();
                          this.props.history.push({
                            pathname: `patient/${patient.id}/management`,
                            state: { primaryRole: this.state.primaryRole },
                          });
                        }}>
                        Management plans
                      </Button>
                    </div>
                  )}
                </>
              </div>
            </div>
          );
        });

      return searchResults;
    }

    return;
  };

  private renderRegistrationSignUpStatus = (patient: PatientSearchQueryItem): JSX.Element => {
    let icon = <ErrorOutlineIcon color="warning" className="icon" />;
    let registrationStatus = 'Not provided';
    if (patient.userProfile.registrationAccessType !== '') {
      icon = <Success className="signed-up icon" />;
      if (patient.userProfile.registrationAccessType === 'atHome') {
        registrationStatus = 'At home';
      } else if (patient.userProfile.registrationAccessType === 'inClinic') {
        registrationStatus = 'In clinic';
      }
    }
    return (
      <div className="registration-status-container">
        {icon}
        <div data-test-id="registration-status">{registrationStatus}</div>
      </div>
    );
  };

  private renderNoSearchResults() {
    const lineText = this.searchTerm ? `"${this.searchTerm}"` : 'your selected filters';

    return (
      <Typography
        style={{
          margin: '48px 0px 48px 8px',
          fontWeight: 'bold',
        }}
        variant="h6"
        id="patient-search-no-results">
        {COPY.NO_RESULTS}
        &nbsp;{`${lineText}`}
      </Typography>
    );
  }

  private renderSearchTips = (resultsFound: boolean, searchTerm: boolean): JSX.Element => {
    let message = COPY.CANT_FIND_SEARCH_TIP;
    if (!resultsFound) {
      message = COPY.SEARCH_TIPS;
    }

    return (
      <StyledPatientSearchTips id="patient-search-tips">
        <div id="patient-search-tips-heading">{message}</div>
        <div id="patient-search-tips-points">
          <ul>
            {searchTerm && (
              <li>
                <BulletPoint />
                {COPY.CHECK_SPELLING}
              </li>
            )}
            <li>
              <BulletPoint />
              {COPY.NEW_MOSAIQ_PATIENT_WAIT}
            </li>
            <li>
              <BulletPoint />
              {COPY.NAME_OR_ID_MESSAGE}
            </li>
            <li>
              <BulletPoint />
              {COPY.CLEAR_STATUS_FILTER}
            </li>
          </ul>
        </div>
      </StyledPatientSearchTips>
    );
  };

  private renderContentDetails = (title: string, value?: string | JSX.Element): JSX.Element => {
    let displayedValue = value;
    if (displayedValue === undefined || displayedValue === null || displayedValue === '') {
      displayedValue = 'Not provided';
    }

    return (
      <div className="patient-search-content-row">
        <div className="patient-search-title-column">{title}&#58;</div>
        <div className="patient-search-value-column">{displayedValue}</div>
      </div>
    );
  };

  private renderPXSignUp = (signedUp: boolean | null): JSX.Element => {
    let icon = <ErrorOutlineIcon color="warning" className="icon" />;
    let message = COPY.NOT_SIGNED_UP;

    if (signedUp === true) {
      icon = <Success className="signed-up icon" />;
      message = COPY.SIGNED_UP;
    } else if (signedUp === false) {
      icon = <ErrorCross className="opted-out icon" />;
      message = COPY.OPTED_OUT;
    }

    return (
      <div className="patient-search-portal-status-container">
        {icon}
        <span className="patient-search-portal-status">{message}</span>
      </div>
    );
  };

  private createAddress = (address?: PatientSearchQueryItemAddress): JSX.Element | undefined => {
    if (address) {
      const { line1, line2, city, state, country, postcode } = address;

      if (line1 || city || state || country || postcode) {
        return (
          <Fragment>
            <div>
              {line1}&#44;
              <br />
              {this.renderLine2(line2)}
              {city}&#44;
              <br />
              {state} {country} {postcode}
            </div>
          </Fragment>
        );
      }
    }

    return undefined;
  };

  private renderLine2 = (line2?: string): JSX.Element => {
    if (line2) {
      return (
        <Fragment>
          {line2}&#44;
          <br />
        </Fragment>
      );
    }

    return <Fragment />;
  };

  private handleGetPINModalOpen = async (
    patientItem: PatientSearchQueryItem,
    form = COPY.PATIENT_REGISTRATION,
  ): Promise<void> => {
    let modalHeader = undefined;
    const formType = form;

    // Distress Pin has a different header
    if (formType === FormType.DISTRESS) {
      modalHeader = COPY.DISTRESS_PIN;
    }

    // Using ID instead of ida since the ida could potentially be blank, but if a result is found the id will always exist.
    this.setState({ buttonLoadingId: patientItem.id, modalHeader: modalHeader });

    const lockDetails = await this.getLockDetails(patientItem);

    if (lockDetails.readOnly) {
      // Release the lock after the PIN has been generated so that another user can immediately access the record if needed.
      this.setState({
        ...this.state,
        registrationInviteModalOpen: true,
        selectedName: `${patientItem.firstName} ${patientItem.lastName}`,
        selectedPatient: patientItem,
        selectedPatientId: patientItem.id,
        selectedDob: patientItem.dob,
        buttonLoadingId: '',
        selectedPatientIda: patientItem.ida,
        selectedAddress: patientItem.address,
        selectedPatientOptIn: {
          id: patientItem.id,
          firstName: patientItem.firstName,
          lastName: patientItem.lastName,
          pxOptedIn: patientItem.pxOptedIn,
          primaryPhone: patientItem.primaryPhone,
          email: patientItem.email,
          pxOptOutComment: patientItem.pxOptOutComment,
          emrInstance: patientItem.emrInstance,
        },
      });
      this.releaseLock();
    } else {
      this.openErrorModalAndResetLoading(lockDetails.lockedByName);
    }
  };

  private handleOptInModalOpen = async (patientItem: PatientSearchQueryItem): Promise<void> => {
    // Set the state so a spinner will be displayed on the button
    this.setState({
      optInModalLoadingId: patientItem.id,
    });

    const lockDetails = await this.getLockDetails(patientItem);

    if (lockDetails.readOnly) {
      // Bring up the modal by setting the states
      this.setState({
        optInModalOpen: true,
        optInModalInDOM: true,
        optInModalLoadingId: '',
        selectedPatientId: patientItem.id,
        selectedPatientIda: patientItem.ida,
        selectedAddress: patientItem.address,
        selectedDob: patientItem.dob,
        selectedPatientOptIn: {
          id: patientItem.id,
          firstName: patientItem.firstName,
          lastName: patientItem.lastName,
          pxOptedIn: patientItem.pxOptedIn,
          primaryPhone: patientItem.primaryPhone,
          email: patientItem.email,
          pxOptOutComment: patientItem.pxOptOutComment,
          emrInstance: patientItem.emrInstance,
        },
      });
    } else {
      this.openErrorModalAndResetLoading(lockDetails.lockedByName);
    }
  };

  private getLockDetails = async (
    patientItem: PatientSearchQueryItem,
  ): Promise<{ readOnly: boolean; lockedByName: string | undefined }> => {
    // Before opening and setting the state for the modal, attempt to acquire the lock before opening the modal
    const { client } = this.props;
    if (!client) throw new Error('Client not found');
    const result: { data: { patient: Patient } } = await client.query({
      query: RECORD_LOCK_QUERY,
      fetchPolicy: 'no-cache',
      variables: { id: patientItem.id },
    });

    const {
      data: { patient },
    } = result;

    if (patient && patient.lock && !patient.lock.readOnly) {
      return { readOnly: true, lockedByName: undefined }; // Lock was acquired
    }

    return {
      readOnly: false,
      lockedByName: patient.lock ? patient.lock.lockedByName : 'a staff member' || '',
    }; // Lock wasn't acquired
  };

  private openErrorModalAndResetLoading = (lockedByName?: string): void => {
    if (lockedByName) {
      const RECORD_IN_USE_BY = COPY.RECORD_IN_USE_BY;
      const RECORD_IN_USE_NO_VIEW = COPY.RECORD_IN_USE_NO_VIEW;
      const lockErrorText = `${RECORD_IN_USE_BY} ${lockedByName}${RECORD_IN_USE_NO_VIEW}`;
      this.setState({ lockErrorModalOpen: true, optInModalLoadingId: '', buttonLoadingId: '', lockErrorText });
    }
  };

  private releaseLock = (): void => {
    if (this.state.selectedPatientId === '') return;
    this.props.client?.mutate({
      mutation: RELEASE_LOCK_MUTATION,
      variables: { accessPatientId: this.state.selectedPatientId },
    });
  };

  private submitHandler = (event: FormEvent<HTMLFormElement | HTMLButtonElement>): void => {
    if (!this.filterOrTermSelected()) {
      event.preventDefault();
    }
  };

  private handleSendPXEmailInvitation = (patientEmail?: string): void => {
    if (patientEmail) {
      this.props.client
        ?.mutate({
          mutation: SEND_PX_EMAIL_INVITATION,
          variables: {
            recipientEmail: patientEmail,
          },
        })
        .then((result): void => {
          logger.info('Invite link is:', result.data.sendPxEmailInvitation.inviteLink);
          this.setState({
            modalPatientRegAcces: false,
          });
        });
    }
  };

  private handleSaveOptIn = (graphData: GraphUpdate[], optedIn: boolean, shouldSubmit: boolean): void => {
    logger.debug('handleSaveOptIn', JSON.stringify(graphData), JSON.stringify(optedIn));
    const { selectedPatientOptIn } = this.state;
    let mutationParameters = '';
    let functionParameters = '';
    let functionValues = '';
    let mutationVariables: { [key: string]: string | boolean } = { id: selectedPatientOptIn.id, pxOptedIn: optedIn };

    // Need to move this outside since we will need this later when opted in for the email.
    let email = '';

    if (optedIn) {
      let primaryPhone = '';
      graphData.forEach((graphItem: GraphUpdate) => {
        if (graphItem.key === 'email') {
          email = graphItem.value as string;
        } else if (graphItem.key === 'primaryPhone') {
          primaryPhone = graphItem.value as string;
        }
      });

      // Break out of the save if there is no email or primary phone
      //as
      if (email === '' || primaryPhone === '') {
        return;
      }

      mutationParameters = '$email: String, $primaryPhone: String';
      functionParameters = 'email: $email, primaryPhone: $primaryPhone';
      functionValues = 'email\nprimaryPhoneFormatted\nprimaryPhone\n';
      mutationVariables = { ...mutationVariables, email, primaryPhone };
    } else {
      const [graphItem] = graphData;

      // The key is not a px comment, so break out and stop the save, as there is an error here.
      if (graphItem.key !== 'pxOptOutComment') {
        return;
      }

      const pxOptOutComment = graphItem.value;

      mutationParameters = '$pxOptOutComment: String';
      functionParameters = 'pxOptOutComment: $pxOptOutComment';
      functionValues = 'pxOptOutComment';
      mutationVariables = { ...mutationVariables, pxOptOutComment };
    }

    const mutationString = `mutation UpdatePatient($id: ID!, $pxOptedIn: Boolean, ${mutationParameters}) {
      updatePatient(id: $id, pxOptedIn: $pxOptedIn, ${functionParameters}) {
        patient {
          id
          pxOptedIn
          ${functionValues}
        }
      }
    }`;

    logger.debug(
      'handleSaveOptIn',
      'Parameters:',
      mutationParameters,
      functionParameters,
      functionValues,
      mutationVariables,
    );
    // Send the mutation
    this.props.client
      ?.mutate({
        mutation: gql(mutationString),
        variables: { ...mutationVariables },
      })
      .then((): void => {
        logger.debug('handleSaveOptIn', 'Saved the mutation');
        // Send the email invitation if the patient has been opted in
        if (optedIn) {
          this.handleSendPXEmailInvitation(email);
          if (shouldSubmit) {
            this.submitRegistration(false);
          }
        }
        this.setState({ isOptInMutationLoading: false });
      })
      .then(() =>
        // Dismiss the modal once the save has occurred
        this.resetOptInModalStates(),
      );
  };

  private resetOptInModalStates = (): void => {
    this.setState({
      optInModalOpen: false,
      selectedPatientOptIn: { ...EMPTY_PATIENT_OPT_IN },
      selectedPatientId: '',
    });
  };

  private submitRegistration = (includePdf = false, fromHomeRego = false): void => {
    const { client } = this.props;
    const {
      selectedPatientOptIn: { id },
    } = this.state;

    client
      ?.mutate({
        mutation: CREATE_SUBMISSION,
        variables: {
          patientID: id,
          includePdf: includePdf,
          updateFormStatus: false,
          fromHomeRego: fromHomeRego,
        },
      })
      .then((response): void => {
        if (response.data && response.data.createSubmission) {
          if (response.data.createSubmission.submission && response.data.createSubmission.submission.pdf) {
            logger.debug(
              'submitRegistration',
              `Response came back: /server/media/${response.data.createSubmission.submission.pdf}`,
            );
          }
        }
      });
  };
}

// Wrap in tranlsations
const apolloPatientSearch = withApollo<Props>(PatientSearch);
export default withRouter(apolloPatientSearch);
